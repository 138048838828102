<div class="content-header">
  <div class="title">
    Call Logs and Recordings
  </div>
  <div class="subtitle">
    View the call logs and recordings of your phone numbers here.
  </div>
</div>


<div class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table">
        <div class="card-table-header">
          <div class="align-left">
            <div class="title">
              Call Logs & Recordings
            </div>
          </div>
          <div class="align-right">
            <button class="btn btn-primary"><i class="material-icons">filter_list</i><span>Filter</span></button>
          </div>
        </div>

        <!--<table class="card-table-body">
          <tr>
            <th>Phone Number</th>
            <th>In-Bound</th>
            <th>Out-Bound</th>
            <th>Dial Plan</th>
          </tr>
          <tr>
            <td>+91 8297440072</td>
            <td><span class="badge green">Active</span></td>
            <td><span class="badge red">In-Active</span></td>
            <td>
              <span class="icon"><img src="assets/images/label-icons/bot_w.svg"></span>
              <span class="title">Biva Bot</span>
            </td>
          </tr>
        </table>-->
        <div class="empty-view">
          <div class="icon"><img src="assets/images/icons/empty.svg"></div>
          <div class="message">You don't have any calls logged!</div>
        </div>
      </div>
    </div>
  </div>

</div>
