import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login/login.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonsModule } from "../commons/commons.module";
import { ModalsModule } from "../modals/modals.module";
import { FormsModule } from "@angular/forms";
import { SwiperModule } from "ngx-swiper-wrapper-v-13";

@NgModule({
  declarations: [LoginComponent],
  imports: [
    SwiperModule,
    CommonModule,
    FormsModule,
    NgbModule,
    CommonsModule,
    ModalsModule,
  ],
})
export class LoginModule {}
