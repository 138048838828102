import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SecurityService} from '../../commons/security.service';
import {ToastrService} from 'ngx-toastr';
import {UtilsService} from '../../commons/utils.service';
import * as _ from 'lodash';
import {HttpService, METHOD} from '../../commons/http.service';

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss']
})
export class CreateEditUserComponent implements OnInit {
  @Input() data;
  public modalTitle = 'Add New User';
  public okButton = 'Add';
  public userForm: any = {
    first_name: null,
    last_name: null,
    email: null,
    organization: null,
    role_type: null,
    username: null,
    password: null,
    confirmPassword: null,
    is_staff: false
  };
  public userProfile: any;
  public organizations: any;
  public roleTypes = [{value: 'developer', label: 'Developer'}, {value: 'businessuser', label: 'Business Analyst'},
    {value: 'admin', label: 'Admin'}];

  constructor(public activeModal: NgbActiveModal, private securityService: SecurityService, private toastr: ToastrService,
              private utilService: UtilsService, private httpService: HttpService) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    if (this.data.isEdit) {
      this.modalTitle = 'Edit User';
      this.okButton = 'Save';
      this.userForm = _.cloneDeep(this.data.userDetails);
      if (this.userForm.is_staff) {
        this.userForm.role_type = _.find(this.roleTypes, {value: 'admin'});
      } else {
        this.userForm.role_type = _.find(this.roleTypes, {value: this.userForm.role_type});
      }
    }
    if (this.data.isFromEditProfile) {
      this.modalTitle = 'Edit Your Profile';
    }
    if (this.userProfile.organization_name.toLowerCase() === 'convojet') {
      this.organizations = this.data.organizations;
    }
  }

  private validateOnCreate() {
    if (!this.userForm.first_name || !this.userForm.last_name || !this.userForm.email ||
      (this.userProfile.organization_name.toLowerCase() === 'convojet' && !this.userForm.organization) ||
      !this.userForm.password || !this.userForm.role_type || !this.userForm.username) {
      this.toastr.error('Please fill all the mandatory fields!');
      return;
    }
    if (!this.utilService.validateEmail(this.userForm.email)) {
      this.toastr.error('Invalid Email format, Please check your email!');
      return;
    }
    if (!this.utilService.validatePassword(this.userForm.password)) {
      this.toastr.error('Password policy does not match, Please choose other password!');
      return;
    }
    if (this.userForm.password !== this.userForm.confirmPassword) {
      this.toastr.error('Passwords does not match!');
      return;
    }
    return true;
  }

  private validateOnEdit() {
    if (!this.userForm.first_name || !this.userForm.last_name || !this.userForm.email || !this.userForm.role_type
      || !this.userForm.username) {
      this.toastr.error('Please fill all the mandatory fields!');
      return;
    }
    if (!this.utilService.validateEmail(this.userForm.email)) {
      this.toastr.error('Invalid Email format, Please check your email!');
      return;
    }
    return true;
  }

  accept() {
    if (this.data.isEdit && !this.validateOnEdit()) {
      return;
    }
    if (!this.data.isEdit && !this.validateOnCreate()) {
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.userForm);
    delete payload.confirmPassword;
    if (!this.data.isEdit) {
      if (payload.organization) {
        payload.organization = payload.organization.id;
      } else {
        delete payload.organization;
      }
    }
    if (payload.role_type.value === 'admin') {
      payload.role_type = 'developer';
      payload.is_staff = true;
    } else {
      payload.role_type = payload.role_type.value;
      payload.is_staff = false;
    }
    const params: any = {
      userId: this.userProfile.id
    };
    let method = METHOD.POST;
    let endpoint = HttpService.ENDPOINTS.userList;
    if (this.data.isEdit) {
      endpoint = HttpService.ENDPOINTS.userDetail;
      params.customerId = this.data.userDetails.id;
      method = METHOD.PUT;
    }
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.activeModal.close();
        this.utilService.hideLoading();
        if (this.data.isFromEditProfile) {
          this.toastr.success('Your Profile is updated Successfully!');
        } else if (this.data.isEdit) {
          this.toastr.success('User updated Successfully!');
        } else {
          this.toastr.success('User created Successfully!');
        }
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      }
    });
  }

}
