import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { UtilsService } from "../../commons/utils.service";
import { HttpService, METHOD } from "../../commons/http.service";
import { SecurityService } from "../../commons/security.service";
import * as _ from "lodash";
import { CreateEditDidComponent } from "../../modals/create-edit-did/create-edit-did.component";
import { WarningMessageComponent } from "../../modals/warning-message/warning-message.component";

@Component({
  selector: "app-didconf",
  templateUrl: "./didconf.component.html",
  styleUrls: ["./didconf.component.scss"],
})
export class DidconfComponent implements OnInit {
  public userProfile: any;
  public didConfs: any;
  private agentExtensions: any;
  private botExtensions: any;

  constructor(
    private utilService: UtilsService,
    private httpService: HttpService,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    this.getAllDIDConfs();
    this.getAllAgentExtensions();
    this.getAllBotExtensions();
  }

  private getAllDIDConfs() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id,
    };
    this.httpService.request(
      HttpService.ENDPOINTS.didList,
      METHOD.GET,
      params,
      payload,
      {
        success: (data) => {
          console.log("this.didConfs >> ", this.didConfs);
          this.didConfs = data;
          this.utilService.hideLoading();
        },
        failure: (err) => {
          this.utilService.hideLoading();
          this.toastr.error(err.error);
        },
      }
    );
  }

  private getAllBotExtensions() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id,
    };
    this.httpService.request(
      HttpService.ENDPOINTS.botList,
      METHOD.GET,
      params,
      payload,
      {
        success: (data) => {
          this.botExtensions = data;
          this.utilService.hideLoading();
        },
        failure: (err) => {
          this.utilService.hideLoading();
          this.toastr.error(err.error);
        },
      }
    );
  }

  private getAllAgentExtensions() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id,
    };
    this.httpService.request(
      HttpService.ENDPOINTS.agentList,
      METHOD.GET,
      params,
      payload,
      {
        success: (data) => {
          this.agentExtensions = data;
          this.utilService.hideLoading();
        },
        failure: (err) => {
          this.utilService.hideLoading();
          this.toastr.error(err.error);
        },
      }
    );
  }

  openCreateEditDIDConf(didConf) {
    const data = {
      didConf,
      isEdit: false,
      agentExtensions: this.agentExtensions,
      botExtensions: this.botExtensions,
    };
    if (didConf) {
      data.isEdit = true;
    }
    const wModelRef = this.utilService.openModel(CreateEditDidComponent, data);
    wModelRef.result.then(
      () => {
        this.getAllDIDConfs();
      },
      () => {}
    );
  }

  deleteDIDConf(didConf) {
    const wModelRef = this.utilService.openModel(WarningMessageComponent, {
      okButton: "Yes, Delete!",
      message: "Are you sure you want to delete this DID Number?",
    });
    wModelRef.result.then(
      (result) => {
        if (result === "OK") {
          this.utilService.showLoading();
          const params = {
            userId: this.userProfile.id,
            didId: didConf.id,
          };
          this.httpService.request(
            HttpService.ENDPOINTS.didDetail,
            METHOD.DELETE,
            params,
            {},
            {
              success: () => {
                this.utilService.hideLoading();
                this.getAllDIDConfs();
                this.toastr.success(
                  "DID Number configuration deleted Successfully!"
                );
              },
              failure: (err) => {
                this.utilService.hideLoading();
                this.toastr.error(err.error);
              },
            }
          );
        }
      },
      () => {}
    );
  }

  getIncomingMapName(didConf) {
    if (didConf.inbound_map === "bot") {
      const mappedExtension = _.find(this.botExtensions, {
        id: didConf.bot_extension,
      });
      return mappedExtension ? mappedExtension.name : "";
    } else {
      const mappedExtension = _.find(this.agentExtensions, {
        id: didConf.agent_extension,
      });
      return mappedExtension ? mappedExtension.name : "";
    }
  }
}
