import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { SecurityService } from "../../commons/security.service";
import { UtilsService } from "../../commons/utils.service";
import { HttpService, METHOD } from "../../commons/http.service";
import * as _ from "lodash";
import { SwiperDirective } from "ngx-swiper-wrapper-v-13";
import { SwiperOptions } from "swiper";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ScrollbarOptions } from "swiper/types/modules/scrollbar";

@Component({
  selector: "app-create-edit-bot",
  templateUrl: "./create-edit-bot.component.html",
  styleUrls: ["./create-edit-bot.component.scss"],
})
export class CreateEditBotComponent implements OnInit {
  @Input() data;
  public modalTitle = "Create Bot Extension";
  public botForm: any;
  public showFinishButton = false;
  public userProfile: any;
  selected_method: any;

  private scrollbar: ScrollbarOptions = {
    el: ".component-host-scrollable",
    hide: true,
    draggable: false,
  };

  public swiperConfig: SwiperOptions = {
    a11y: { enabled: true },
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: this.scrollbar,
    navigation: true,
    pagination: false,
  };

  public asrProviders = [
    { value: "google", label: "Google Speech API" },
    { value: "deepgram", label: "Deepgram Speech API" },
  ];
  public ttsProviders = [
    { value: "google", label: "Google Speech API" },
    { value: "polly", label: "Amazon Polly" },
  ];

  public googlettsCredentials = [
    { value: "rest", label: "Rest API" },
    { value: "service", label: "Service Account" },
  ];

  public apiMethods = [
    { value: "post", label: "POST" },
    { value: "get", label: "GET" },
  ];

  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;

  constructor(
    public activeModal: NgbActiveModal,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private utilService: UtilsService,
    private httpService: HttpService
  ) {
    this.userProfile = this.securityService.userProfile;
    this.botForm = utilService.getBotProperties();
  }

  ngOnInit() {}

  private validate() {
    if (
      !this.botForm.name ||
      !this.botForm.extension ||
      !this.botForm.bot_config.webhookUrl ||
      !this.botForm.bot_config.token ||
      !this.botForm.asr_config.provider ||
      !this.botForm.tts_config.provider
    ) {
      this.toastr.error("Please fill all the mandatory fields!");
      return;
    }
    if (
      this.botForm.asr_config.provider.value === "google" &&
      (!this.botForm.asr_config.private_key ||
        !this.botForm.asr_config.client_email ||
        !this.botForm.asr_config.projectId ||
        !this.botForm.asr_config.languageCode)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Google's Speech Recognition Engine!"
      );
      return;
    }
    if (
      this.botForm.asr_config.provider.value === "deepgram" &&
      (!this.botForm.asr_config.url || !this.botForm.asr_config.apiKey)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Deepgram Speech Recognition Engine!"
      );
      return;
    }
    if (
      this.botForm.tts_config.provider.value === "google" &&
      this.botForm.tts_config.credential_type.value === "rest" &&
      (!this.botForm.tts_config.apiKey ||
        !this.botForm.tts_config.url ||
        !this.botForm.tts_config.method.value ||
        !this.botForm.tts_config.voice.name ||
        !this.botForm.tts_config.voice.languageCode ||
        !this.botForm.tts_config.voice.ssmlGender)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Google's Text to Speech Engine!"
      );
      return;
    }
    if (
      this.botForm.tts_config.provider.value === "google" &&
      this.botForm.tts_config.credential_type.value === "service" &&
      (!this.botForm.tts_config.private_key ||
        !this.botForm.tts_config.client_email ||
        !this.botForm.tts_config.projectId ||
        !this.botForm.tts_config.voice.name ||
        !this.botForm.tts_config.voice.languageCode ||
        !this.botForm.tts_config.voice.ssmlGender)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Google's Text to Speech Engine!"
      );
      return;
    }
    if (
      this.botForm.tts_config.provider.value === "polly" &&
      (!this.botForm.tts_config.accessKeyId ||
        !this.botForm.tts_config.secretAccessKey ||
        !this.botForm.tts_config.region ||
        !this.botForm.tts_config.VoiceId ||
        !this.botForm.tts_config.languageCode)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Amazon Polly's Text to Speech Engine!"
      );
      return;
    }
    return true;
  }

  onPageChange(event) {
    this.showFinishButton = event === 3;
  }

  accept() {
    if (!this.validate()) {
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.botForm);
    payload.tts_config.provider = payload.tts_config.provider.value;
    payload.asr_config.provider = payload.asr_config.provider.value;
    const params: any = {
      userId: this.userProfile.id,
    };
    const method = METHOD.POST;
    const endpoint = HttpService.ENDPOINTS.botList;
    console.log("Payload >>> ", payload);
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.activeModal.close();
        this.utilService.hideLoading();
        this.toastr.success("Bot Extension is created Successfully!");
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      },
    });
  }

  nextSlide() {
    this.directiveRef.nextSlide();
  }
  previousSlide() {
    this.directiveRef.prevSlide();
  }

  removeUnwantedData(data: any) {
    if (data.value == "rest") {
      this.botForm.tts_config.projectId = null;
      this.botForm.tts_config.client_email = null;
      this.botForm.tts_config.private_key = null;
      this.botForm.tts_config.languageCode = null;
    }

    if (data.value == "service") {
      this.botForm.tts_config.apiKey = null;
      this.botForm.tts_config.languageCode = null;
      this.botForm.tts_config.url = null;
    }
  }

  removeTTSData(data: any) {
    if (data.value == "google") {
      this.botForm.tts_config.accessKeyId = null;
      this.botForm.tts_config.secretAccessKey = null;
      this.botForm.tts_config.languageCode = null;
    }
    if (data.value == "polly") {
      this.botForm.tts_config.projectId = null;
      this.botForm.tts_config.client_email = null;
      this.botForm.tts_config.private_key = null;
      this.botForm.tts_config.languageCode = null;
      this.botForm.tts_config.apiKey = null;
      this.botForm.tts_config.url = null;
    }
  }
}
