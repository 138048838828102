<div class="modal-header">
  <div class="modal-title">{{modalTitle}}</div>
  <div class="modal-title-image"><img src="assets/images/icons/agent.svg"></div>
  <div class="modal-subtitle">Create or edit the live human agent extensions who can take calls from local SIP soft phone or any external phone.
  </div>

  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')">close</i>
</div>
<div class="modal-body">
  <form>
    <div class="form-group col-md-6">
      <label>First Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="firstname" [(ngModel)]="agentForm.data.firstname" class="form-control"
             placeholder="Agent's First Name" required>
    </div>
    <div class="form-group col-md-6">
      <label>Last Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="lastname" [(ngModel)]="agentForm.data.lastname" class="form-control"
             placeholder="Agent's Last Name" required>
    </div>
    <div class="form-group col-md-12">
      <label>Extension Number<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="extension" [(ngModel)]="agentForm.extension" class="form-control"
             placeholder="Agent's Extension Number" required>
    </div>
    <div class="form-group col-md-12">
      <label>Live Agent Type<sup class="mandatory-symbol">*</sup></label>
      <div ngbDropdown container="body">
        <div ngbDropdownToggle id="agent-type-dropdown" class="form-control dropdown-control">
          <div class="placeholder" [ngClass]="{selected: !!agentForm.type}">
            <span *ngIf="!agentForm.type">Select User Role</span>
            <span *ngIf="agentForm.type">{{agentForm.type.label}}</span>
            <i class="material-icons">expand_more</i>
          </div>
        </div>
        <div ngbDropdownMenu aria-labelledby="agent-type-dropdown" class="modal-dropdown">
          <div ngbDropdownItem class="dropdown-btn" *ngFor="let type of agentTypes" (click)="agentForm.type = type">
            {{type.label}}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col-md-12" *ngIf="agentForm.type && agentForm.type.value==='internal'">
      <label>Agent's username for SIP login<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="extension" [(ngModel)]="agentForm.extension" class="form-control" readonly
             placeholder="Agent's username for SIP login" required>
    </div>
    <div class="form-group col-md-6" *ngIf="agentForm.type && agentForm.type.value==='internal' && !data.isEdit">
      <label>Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" name="password" [(ngModel)]="agentForm.data.secret" autocomplete="new-password"
             role="presentation" class="form-control" placeholder="Password for SIP login credentials" required>
    </div>
    <div class="form-group col-md-6" *ngIf="agentForm.type && agentForm.type.value==='internal' && !data.isEdit">
      <label>Confirm Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" autocomplete="new-password" name="confirmPassword" [(ngModel)]="confirmPassword" class="form-control"
             placeholder="Re-enter the password" required>
    </div>
    <div class="form-group col-md-12 hint-text"
         *ngIf="agentForm.type && agentForm.type.value==='internal' && !data.isEdit">
      Password must contain atleast one lower case, one upper case, one numeric and one special character
      (!,@,#,$,%,^,&,*) and with total length more than 8.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="accept()">{{okButton}}</button>
</div>
