<div class="shell">
  <app-sidebar></app-sidebar>
  <div class="shell-body">
    <div class="shell-header">
      <app-header></app-header>
    </div>
    <div class="shell-content">
      <router-outlet name="shell"></router-outlet>
    </div>
    <div class="shell-footer">
      © 2019 Nuacem. All rights reserved.
    </div>
  </div>
  <ngx-spinner name="appSpinner"></ngx-spinner>
</div>
