<div class="main-content">
  <div class="left-content">
    <div class="header">
      <div class="logo">
        <div class="title"></div>
        <div class="logo"><img src="assets/images/logos/logo-full-white.svg"></div>
      </div>
    </div>

    <div id="login-slides" class="swiper-container" *ngIf="loginSlides" [swiper]="swiperConfig">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let loginSlide of loginSlides">
          <img [src]="loginSlide.img" alt="{{loginSlide.title}}">
          <div class="caption">
            <div class="title">{{loginSlide.title}}</div>
            <div class="subtitle">{{loginSlide.subtitle}}</div>
          </div>
        </div>
      </div>

      <div class="swiper-pagination"></div>
    </div>

    <div class="footer">
      © 2019 Nuacem. All rights reserved.
    </div>
  </div>

  <div class="right-content">
    <div class="login-block">
      <div class="title">Welcome Back</div>
      <div class="subtitle">Login to your ConvoJet dashboard to manage your voice bots. Or new user
        <span class="link" (click)="openRequestAccess()">Request for access</span>
      </div>
      <form class="text-center" (ngSubmit)="signIn()">
        <input type="text" [(ngModel)]="loginForm.username" class="form-control" placeholder="Username" name="username">
        <div class="password-field"><input type="password" [(ngModel)]="loginForm.password"  name="password" class="form-control" placeholder="Password"><i class="material-icons show-password">visibility_off</i></div>
        <button app-loading-button="{{isSigningIn}}" class="btn btn-primary" type="submit" >SIGN IN</button>
      </form>

      <div class="error-msg">
        Sorry, something went wrong. Try again later!
      </div>
    </div>
    <div class="login-background">
      <img src="assets/images/login-bg.svg">
    </div>
  </div>
</div>
