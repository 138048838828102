import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SecurityService} from '../../commons/security.service';
import {ToastrService} from 'ngx-toastr';
import {UtilsService} from '../../commons/utils.service';
import * as _ from 'lodash';
import {HttpService, METHOD} from '../../commons/http.service';

@Component({
  selector: 'app-create-edit-organization',
  templateUrl: './create-edit-organization.component.html',
  styleUrls: ['./create-edit-organization.component.scss']
})
export class CreateEditOrganizationComponent implements OnInit {
  @Input() data;
  public userProfile: any;
  public modalTitle = 'Add New Organization';
  public okButton = 'Add';
  public orgForm = {
    name: null
  };

  constructor(public activeModal: NgbActiveModal, private securityService: SecurityService, private toastr: ToastrService,
              private utilService: UtilsService, private httpService: HttpService) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    if (this.data.isEdit) {
      this.modalTitle = 'Edit Organization';
      this.okButton = 'Save';
      this.orgForm = _.cloneDeep(this.data.organization);
    }
  }

  accept() {
    if (!this.orgForm.name) {
      this.toastr.error('Please fill all the mandatory fields!');
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.orgForm);
    const params: any = {
      userId: this.userProfile.id
    };
    let method = METHOD.POST;
    let endpoint = HttpService.ENDPOINTS.orgList;
    if (this.data.isEdit) {
      params.orgId = this.data.organization.id;
      method = METHOD.PUT;
      endpoint = HttpService.ENDPOINTS.orgDetail;
    }
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.activeModal.close();
        this.utilService.hideLoading();
        if (this.data.isEdit) {
          this.toastr.success('Organization updated Successfully!');
        } else {
          this.toastr.success('Organization created Successfully!');
        }
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      }
    });
  }


}
