<div class="modal-header">
  <div class="modal-title">Request for Access</div>
  <div class="modal-title-image"><img src="assets/images/icons/key.svg"></div>
  <div class="modal-subtitle">Request for a new ConvoJet account by providing details below</div>
  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')">close</i>
</div>
<div class="modal-body">
  <form>
    <div class="form-group col-md-6">
      <label>First Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" class="form-control" placeholder="Your First Name">
    </div>
    <div class="form-group col-md-6">
      <label>Last Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" class="form-control" placeholder="Your Last Name">
    </div>
    <div class="form-group col-md-12">
      <label>Email ID<sup class="mandatory-symbol">*</sup></label>
      <input type="text" class="form-control" placeholder="Your Company Email Address">
    </div>
    <div class="form-group col-md-12">
      <label>Mobile Number<sup class="mandatory-symbol">*</sup></label>
      <input type="text" class="form-control" placeholder="Your Mobile Number">
    </div>
    <div class="form-group col-md-12">
      <label>Company Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" class="form-control" placeholder="Your Company Name">
    </div>
    <div class="form-group col-md-12">
      <label>Message<sup class="mandatory-symbol">*</sup></label>
      <textarea class="form-control" placeholder="Your Company Name"></textarea>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Close click')">Request Access</button>
</div>
