import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {SecurityService} from '../../commons/security.service';
import {WarningMessageComponent} from '../../modals/warning-message/warning-message.component';
import {UtilsService} from '../../commons/utils.service';
import {HttpService, METHOD} from '../../commons/http.service';
import {Event as NavigationEvent} from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public currentRoute: string;
  public userProfile: any;

  constructor(private router: Router, private securityService: SecurityService, private utilService: UtilsService,
              private httpService: HttpService) {
    this.currentRoute = this.router.url;
    this.userProfile = this.securityService.userProfile;
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
      }
    });
  }

  ngOnInit() {
  }

  openLogoutWarning() {
    const wModelRef = this.utilService.openModel(WarningMessageComponent,
      {okButton: 'Yes, Logout', message: 'Are you sure you want to logout?'});
    wModelRef.result.then((result) => {
      if (result === 'OK') {
        this.utilService.showLoading();
        this.httpService.request(HttpService.ENDPOINTS.logout, METHOD.GET, {}, {}, {
          success: () => {
            this.securityService.logOut();
            setTimeout(() => {
              this.utilService.hideLoading();
              this.router.navigateByUrl('/dashboard/login');
            }, 2000);
          }
        });
      }
    }, () => {});
  }

}
