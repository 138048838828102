<div class="modal-header">
  <div class="modal-title">{{modalTitle}}</div>
  <div class="modal-title-image"><img src="assets/images/icons/user.svg"></div>
  <div class="modal-subtitle" *ngIf="!data.isFromEditProfile">Create or edit the users who can access the ConvoJet
    Dashboard
  </div>
  <div class="modal-subtitle" *ngIf="data.isFromEditProfile">Edit Your ConvoJet Profile</div>

  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')">close</i>
</div>
<div class="modal-body">
  <form>
    <div class="form-group col-md-6">
      <label>First Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="first_name" [(ngModel)]="userForm.first_name" class="form-control"
             placeholder="User's First Name" required>
    </div>
    <div class="form-group col-md-6">
      <label>Last Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="last_name" [(ngModel)]="userForm.last_name" class="form-control"
             placeholder="User's Last Name" required>
    </div>
    <div class="form-group col-md-12">
      <label>Email ID<sup class="mandatory-symbol">*</sup></label>
      <input type="email" name="email" [(ngModel)]="userForm.email" class="form-control"
             placeholder="User's Email Address" required>
    </div>
    <div class="form-group col-md-12" *ngIf="!data.isFromEditProfile">
      <label>Username<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="username" [(ngModel)]="userForm.username" role="presentation" class="form-control"
             placeholder="Username for login credentials" required>
    </div>
    <div class="form-group col-md-6" *ngIf="!data.isEdit">
      <label>Default Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" name="password" [(ngModel)]="userForm.password" autocomplete="new-password"
             role="presentation" class="form-control" placeholder="Default Password for login credentials" required>
    </div>
    <div class="form-group col-md-6" *ngIf="!data.isEdit">
      <label>Confirm Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" autocomplete="new-password" name="confirmPassword" [(ngModel)]="userForm.confirmPassword" class="form-control"
             placeholder="Re-enter the password" required>
    </div>
    <div class="form-group col-md-12 hint-text" *ngIf="!data.isEdit">
      Password must contain atleast one lower case, one upper case, one numeric and one special character
      (!,@,#,$,%,^,&,*) and with total length more than 8.
    </div>
    <div class="form-group col-md-12" *ngIf="organizations && !data.isEdit">
      <label>Organization<sup class="mandatory-symbol">*</sup></label>
      <div ngbDropdown container="body">
        <div ngbDropdownToggle id="user-management-org-dropdown" class="form-control dropdown-control">
          <div class="placeholder" [ngClass]="{selected: !!userForm.organization}">
            <span *ngIf="!userForm.organization">Select Organization</span>
            <span *ngIf="userForm.organization">{{userForm.organization.name}}</span>
            <i class="material-icons">expand_more</i>
          </div>
        </div>
        <div ngbDropdownMenu aria-labelledby="user-management-org-dropdown" class="modal-dropdown">
          <div ngbDropdownItem class="dropdown-btn" *ngFor="let org of organizations"
               (click)="userForm.organization = org">{{org.name}}
          </div>
        </div>
      </div>
    </div>
    <div class="form-group col-md-12" *ngIf="!data.isFromEditProfile">
      <label>User Role<sup class="mandatory-symbol">*</sup></label>
      <div ngbDropdown container="body">
        <div ngbDropdownToggle id="user-management-role-dropdown" class="form-control dropdown-control">
          <div class="placeholder" [ngClass]="{selected: !!userForm.role_type}">
            <span *ngIf="!userForm.role_type">Select User Role</span>
            <span *ngIf="userForm.role_type">{{userForm.role_type.label}}</span>
            <i class="material-icons">expand_more</i>
          </div>
        </div>
        <div ngbDropdownMenu aria-labelledby="user-management-role-dropdown" class="modal-dropdown">
          <div ngbDropdownItem class="dropdown-btn" *ngFor="let role of roleTypes" (click)="userForm.role_type = role">
            {{role.label}}
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="accept()">{{okButton}}</button>
</div>
