import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { Location } from "@angular/common";

export enum METHOD {
  POST = "post",
  PUT = "put",
  GET = "get",
  DELETE = "delete",
}

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public static API_PREFIX = "/app";

  public static ENDPOINTS: any = {
    loginCheck: {
      url: "/logincheck",
      queryParams: [],
      excludeAuth: true,
    },
    logout: {
      url: "/logout",
      queryParams: [],
    },
    restartPBX: {
      url: "/restartPBX",
      queryParams: [],
    },
    userList: {
      url: "/:userId/users",
      queryParams: ["get_all"],
    },
    userDetail: {
      url: "/:userId/users/:customerId",
      queryParams: [],
    },
    changePassword: {
      url: "/:userId/users/:customerId/changePassword",
      queryParams: [],
    },
    orgList: {
      url: "/:userId/org",
      queryParams: [],
    },
    orgDetail: {
      url: "/:userId/org/:orgId",
      queryParams: [],
    },
    agentList: {
      url: "/:userId/agentexten",
      queryParams: [],
    },
    agentDetail: {
      url: "/:userId/agentexten/:agentExtensionId",
      queryParams: [],
    },
    changeAgentPassword: {
      url: "/:userId/agentexten/:agentExtensionId/changePassword",
      queryParams: [],
    },
    didList: {
      url: "/:userId/did",
      queryParams: [],
    },
    didDetail: {
      url: "/:userId/did/:didId",
      queryParams: [],
    },
    botList: {
      url: "/:userId/botexten",
      queryParams: [],
    },
    botDetail: {
      url: "/:userId/botexten/:botExtensionId",
      queryParams: [],
    },
  };

  public static PATHPARAM_REGEX: any = /\:([a-zA-Z]+)/g;

  public API_HOST = "https://qa.convojet.com";

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    location: Location
  ) {
    try {
      let loc = location as any;
      loc = loc._platformLocation.location;
      // If not a dev environment override the api domain
      if (loc.hostname !== "localhost") {
        this.API_HOST =
          loc.protocol + "//" + loc.hostname + (loc.port ? ":" + loc.port : "");
      }
    } catch (e) {}
  }

  private static genRandQuery() {
    return "rnd=" + Math.random().toString(36).substr(7);
  }

  private resolveQueryParams(url, qParams, values) {
    // Include some more keys in values before resolving
    const valueKeys = Object.keys(values);
    qParams.forEach((query) => {
      if (
        valueKeys.indexOf(query) > -1 &&
        typeof values[query] !== "undefined" &&
        values[query] !== null
      ) {
        url += url.indexOf("?") > -1 ? "&" : "?";
        url += query + "=" + values[query];
      }
    });

    return url;
  }

  private resolvePathParams(url, values) {
    return url.replace(HttpService.PATHPARAM_REGEX, (matchStr, valName) => {
      const r = values[valName];
      if (
        typeof r === "string" ||
        typeof r === "number" ||
        typeof r === "boolean"
      ) {
        return r;
      }
      return typeof r === "string" ||
        typeof r === "number" ||
        typeof r === "boolean"
        ? r
        : matchStr;
    });
  }

  private prepareAuthHeaders(excludeAuth: boolean) {
    if (excludeAuth) {
      return {};
    }
    let userProfile: any = window.localStorage.getItem("user");
    try {
      userProfile = JSON.parse(userProfile);
    } catch (e) {}

    if (!userProfile || !userProfile.token || !userProfile.csrf_token) {
      window.localStorage.removeItem("user");
      this.router.navigateByUrl("/dashboard/login");
      return;
    }
    return {
      Authorization: "Token " + userProfile.token,
      "X-CSRFToken": userProfile.csrf_token,
    };
  }

  request(
    endpoint: any,
    method: METHOD,
    params: any,
    payload: any,
    callback: any
  ) {
    // Prepare URL
    let url =
      this.API_HOST +
      HttpService.API_PREFIX +
      this.resolvePathParams(endpoint.url, params);
    url = this.resolveQueryParams(url, endpoint.queryParams, params);
    url += url.indexOf("?") > -1 ? "&" : "?";
    url += HttpService.genRandQuery();

    // Prepare HEADERS
    const headers = this.prepareAuthHeaders(endpoint.excludeAuth);
    if (!headers) {
      return;
    }
    if (endpoint.contentType) {
      headers["Content-Type"] = endpoint.contentType;
    } else {
      headers["Content-Type"] = "application/json;charset=UTF-8";
    }

    return this.http
      .request(method.toString(), url, {
        body: payload,
        headers,
      })
      .subscribe(
        (res) => {
          if (callback.success) {
            callback.success(res);
          }
        },
        (err: HttpErrorResponse) => {
          if (err.status === 401 && !endpoint.excludeAuth) {
            window.localStorage.removeItem("user");
            this.toastr.warning("Your session is expired! Please login again.");
            this.router.navigateByUrl("/dashboard/login");
            return;
          }
          if (callback.failure) {
            let error;
            console.log(err);
            if (err.error && err.error.error) {
              error = err.error;
            } else if (err.error && err.error instanceof Object) {
              error = { error: "" };
              _.forOwn(err.error, (value, key) => {
                if (value && value.length > 0) {
                  error.error += "\n" + value[0];
                }
              });
            } else {
              error = {
                error: "Something went wrong! Please try again later.",
              };
            }
            callback.failure(error);
          }
        }
      );
  }
}
