<div class="modal-header">
  <div class="modal-title">{{ modalTitle }}</div>
  <div class="modal-title-image">
    <img src="assets/images/icons/phone.svg" />
  </div>
  <div class="modal-subtitle">Configure your DID Phone number here</div>

  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')"
    >close</i
  >
</div>
<div class="modal-body">
  <div
    class="swiper-container"
    [swiper]="swiperConfig"
    (indexChange)="onPageChange($event)"
  >
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <div class="header-title col-md-12">Basic Details</div>
        <form>
          <div class="form-group col-md-12">
            <label>Label<sup class="mandatory-symbol">*</sup></label>
            <input
              type="text"
              name="label"
              [(ngModel)]="didForm.label"
              class="form-control"
              placeholder="Give a unique label to this phone number"
              required
            />
          </div>
          <div class="form-group col-md-12">
            <label>DID Phone Number<sup class="mandatory-symbol">*</sup></label>
            <input
              type="text"
              name="did_number"
              [(ngModel)]="didForm.did_number"
              class="form-control"
              placeholder="DID Phone number"
              required
            />
          </div>
          <div class="form-group col-12 position-relative">
            <label>Enable In-Bound</label>
            <div class="check-box">
              <ui-switch
                size="small"
                color="#0BBF9C"
                name="is_inbound"
                [(ngModel)]="didForm.is_inbound"
              ></ui-switch>
            </div>
          </div>
          <div class="form-group col-12 position-relative">
            <label>Enable Out-Bound</label>
            <div class="check-box">
              <ui-switch
                size="small"
                color="#0BBF9C"
                name="is_outbound"
                [(ngModel)]="didForm.is_outbound"
              ></ui-switch>
            </div>
          </div>
        </form>
      </div>

      <div class="swiper-slide">
        <div class="header-title col-md-12">In-Bound Rules</div>
        <form>
          <div class="form-group col-md-12">
            <label
              >In-Bound Receiver<sup class="mandatory-symbol">*</sup></label
            >
            <div ngbDropdown container="body">
              <div
                ngbDropdownToggle
                id="inbound-type-dropdown"
                class="form-control dropdown-control"
              >
                <div
                  class="placeholder"
                  [ngClass]="{ selected: !!didForm.inbound_map }"
                >
                  <span *ngIf="!didForm.inbound_map">Select User Role</span>
                  <span *ngIf="didForm.inbound_map">{{
                    didForm.inbound_map.label
                  }}</span>
                  <i class="material-icons">expand_more</i>
                </div>
              </div>
              <div
                ngbDropdownMenu
                aria-labelledby="inbound-type-dropdown"
                class="modal-dropdown"
              >
                <div
                  ngbDropdownItem
                  class="dropdown-btn"
                  *ngFor="let type of inboundMapTypes"
                  (click)="didForm.inbound_map = type"
                >
                  {{ type.label }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="form-group col-md-12"
            *ngIf="didForm.inbound_map && didForm.inbound_map.value === 'agent'"
          >
            <label>Agent Type<sup class="mandatory-symbol">*</sup></label>
            <div ngbDropdown container="body">
              <div
                ngbDropdownToggle
                id="agent-type-dropdown"
                class="form-control dropdown-control"
              >
                <div
                  class="placeholder"
                  [ngClass]="{ selected: !!didForm.inbound_agent_type }"
                >
                  <span *ngIf="!didForm.inbound_agent_type"
                    >Select Agent Type</span
                  >
                  <span *ngIf="didForm.inbound_agent_type">{{
                    didForm.inbound_agent_type.label
                  }}</span>
                  <i class="material-icons">expand_more</i>
                </div>
              </div>
              <div
                ngbDropdownMenu
                aria-labelledby="agent-type-dropdown"
                class="modal-dropdown"
              >
                <div
                  ngbDropdownItem
                  class="dropdown-btn"
                  *ngFor="let type of agentMapTypes"
                  (click)="didForm.inbound_agent_type = type"
                >
                  {{ type.label }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="form-group col-md-12"
            *ngIf="didForm.inbound_map && didForm.inbound_map.value === 'agent'"
          >
            <label>Agent Extension<sup class="mandatory-symbol">*</sup></label>
            <div ngbDropdown container="body">
              <div
                ngbDropdownToggle
                id="internal-agent-dropdown"
                class="form-control dropdown-control"
              >
                <div
                  class="placeholder"
                  [ngClass]="{ selected: !!didForm.agent_extension }"
                >
                  <span *ngIf="!didForm.agent_extension">Select User Role</span>
                  <span *ngIf="didForm.agent_extension"
                    >{{ didForm.agent_extension.name }}
                    {{ didForm.agent_extension.extension }}</span
                  >
                  <i class="material-icons">expand_more</i>
                </div>
              </div>
              <div
                ngbDropdownMenu
                aria-labelledby="internal-agent-dropdown"
                class="modal-dropdown"
              >
                <div
                  ngbDropdownItem
                  class="dropdown-btn"
                  *ngFor="let type of agentExtensions"
                  (click)="didForm.agent_extension = type"
                >
                  {{ type.name }} {{ type.extension }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="form-group col-md-12"
            *ngIf="didForm.inbound_map && didForm.inbound_map.value === 'bot'"
          >
            <label
              >In-Bound Receiver<sup class="mandatory-symbol">*</sup></label
            >
            <div ngbDropdown container="body">
              <div
                ngbDropdownToggle
                id="bot-extension-dropdown"
                class="form-control dropdown-control"
              >
                <div
                  class="placeholder"
                  [ngClass]="{ selected: !!didForm.bot_extension }"
                >
                  <span *ngIf="!didForm.bot_extension"
                    >Select Bot Extension</span
                  >
                  <span *ngIf="didForm.bot_extension"
                    >{{ didForm.bot_extension.name }}
                    {{ didForm.bot_extension.extension }}</span
                  >
                  <i class="material-icons">expand_more</i>
                </div>
              </div>
              <div
                ngbDropdownMenu
                aria-labelledby="bot-extension-dropdown"
                class="modal-dropdown"
              >
                <div
                  ngbDropdownItem
                  class="dropdown-btn"
                  *ngFor="let type of botExtensions"
                  (click)="didForm.bot_extension = type"
                >
                  {{ type.name }} {{ type.extension }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="swiper-slide">
        <div class="header-title col-md-12">DID Provider Configuration</div>
        <form>
          <div class="form-group col-md-12">
            <label>DID Provider<sup class="mandatory-symbol">*</sup></label>
            <div ngbDropdown container="body">
              <div
                ngbDropdownToggle
                id="did-provider-dropdown"
                class="form-control dropdown-control"
              >
                <div
                  class="placeholder"
                  [ngClass]="{ selected: !!didForm.data.provider }"
                >
                  <span *ngIf="!didForm.data.provider"
                    >Select the DID Provider</span
                  >
                  <span *ngIf="didForm.data.provider">{{
                    didForm.data.provider.label
                  }}</span>
                  <i class="material-icons">expand_more</i>
                </div>
              </div>
              <div
                ngbDropdownMenu
                aria-labelledby="did-provider-dropdown"
                class="modal-dropdown"
              >
                <div
                  ngbDropdownItem
                  class="dropdown-btn"
                  *ngFor="let type of didProviders"
                  (click)="didForm.data.provider = type"
                >
                  {{ type.label }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-6">
            <label>Username</label>
            <input
              type="text"
              name="username"
              [(ngModel)]="didForm.data.customConfiguration.username"
              class="form-control"
              placeholder="Username from provider"
              required
            />
          </div>
          <div class="form-group col-md-6">
            <label>Secret</label>
            <input
              type="password"
              autocomplete="new-password"
              name="secret"
              [(ngModel)]="didForm.data.customConfiguration.secret"
              class="form-control"
              placeholder="Secret from provider"
              required
            />
          </div>
          <div class="form-group col-md-6">
            <label>Host<sup class="mandatory-symbol">*</sup></label>
            <input
              type="text"
              name="host"
              [(ngModel)]="didForm.data.customConfiguration.host"
              class="form-control"
              placeholder="Host domain of the provider Ex: proxy.vox-cpaas.com"
              required
            />
          </div>
          <div class="form-group col-md-6">
            <label>Port<sup class="mandatory-symbol">*</sup></label>
            <input
              type="text"
              name="port"
              [(ngModel)]="didForm.data.customConfiguration.port"
              class="form-control"
              placeholder="SIP Port number of the provider Default: 5060"
              required
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="form-nav-prev btn btn-secondary"
    (click)="previousSlide()"
  >
    Previous
  </button>
  <button
    type="button"
    class="form-nav-next btn btn-primary"
    [hidden]="showFinishButton"
    (click)="nextSlide()"
  >
    Next
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="accept()"
    [hidden]="!showFinishButton"
  >
    Save
  </button>
</div>
