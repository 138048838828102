import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'modal-request-for-access',
  templateUrl: './request-for-access.component.html',
  styleUrls: ['./request-for-access.component.scss']
})
export class RequestForAccessComponent implements OnInit {
  @Input() data;

  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit() {
  }

}
