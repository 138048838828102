import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login/login.component';
import {ShellComponent} from './main/shell/shell.component';
import {AdminAuthGuard, AuthGuard, DevAuthGuard, LoginPathGuard} from './commons/security.service';
import {AnalyticsComponent} from './main/analytics/analytics.component';
import {DidconfComponent} from './main/didconf/didconf.component';
import {BotextensionComponent} from './main/botextension/botextension.component';
import {AgentextensionComponent} from './main/agentextension/agentextension.component';
import {UsermanagementComponent} from './main/usermanagement/usermanagement.component';
import {SettingsComponent} from './main/settings/settings.component';
import {LogsComponent} from './main/logs/logs.component';

const routes: Routes = [
  {
    path: 'dashboard/login',
    component: LoginComponent,
    canActivate: [LoginPathGuard]
  },
  {
    path: 'dashboard',
    component: ShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: AnalyticsComponent,
        outlet: 'shell'
      },
      {
        path: 'did',
        component: DidconfComponent,
        outlet: 'shell',
        canActivate: [DevAuthGuard]
      },
      {
        path: 'botexten',
        component: BotextensionComponent,
        outlet: 'shell',
        canActivate: [DevAuthGuard]
      },
      {
        path: 'agentexten',
        component: AgentextensionComponent,
        outlet: 'shell',
        canActivate: [DevAuthGuard]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        outlet: 'shell',
        canActivate: [DevAuthGuard]
      },
      {
        path: 'logs',
        component: LogsComponent,
        outlet: 'shell'
      },
      {
        path: 'usermanagement',
        component: UsermanagementComponent,
        outlet: 'shell',
        canActivate: [AdminAuthGuard]
      }
    ]
  },
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
