<div class="header-wrapper">
  <div class="header-block">
    <div class="header">
      <div class="align-left">
        <span class="title">DASHBOARD &bull; </span> <span class="status live" placement="bottom" ngbTooltip="Your ConvoJet PBX System is up and running!">Running</span>
      </div>


      <div class="align-right">
        <div ngbDropdown>
          <div ngbDropdownToggle>
            <span class="profile-title">
              <span class="title">{{userProfile.first_name}} {{userProfile.last_name}}</span>
              <span class="subtitle">
                <span *ngIf="userProfile.is_staff">Admin</span>
                <span *ngIf="!userProfile.is_staff && userProfile.role_type === 'developer'">Developer</span>
                <span *ngIf="!userProfile.is_staff && userProfile.role_type !== 'developer'">Analyst</span>
              </span>
            </span>
            <span class="icon">
              <img src="assets/images/avatars/user.svg">
            </span>
          </div>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <div class="profile-details">
              <div class="inline-block top icon">
                <img src="assets/images/avatars/user_black.svg">
              </div>
              <div class="inline-block top details">
                <div class="text-label">
                  <span class="bold">{{userProfile.first_name}} {{userProfile.last_name}}</span>
                </div>
                <div class="text-label">
                  <span class="badge bold">
                    <span *ngIf="userProfile.is_staff">Admin</span>
                    <span *ngIf="!userProfile.is_staff && userProfile.role_type === 'developer'">Developer</span>
                    <span *ngIf="!userProfile.is_staff && userProfile.role_type !== 'developer'">Analyst</span>
                  </span>
                </div>
                <div class="text-label">
                  <span class="light"><i class="material-icons">email</i></span>
                  <span class="light">{{userProfile.email}}</span>
                </div>
                <div class="text-label">
                  <span class="light"><i class="material-icons">account_balance</i></span>
                  <span class="light">{{userProfile.organization_name}}</span>
                </div>
              </div>
            </div>
            <div class="dropdown-btn" (click)="openChangePassword(userProfile)"><i class="material-icons">security</i>Change Password</div>
            <div class="dropdown-btn" (click)="openCreateEditUser(userProfile)"><i class="material-icons">edit</i>Edit Profile</div>
          </div>
        </div>
      </div>

      <div class="align-right" *ngIf="userProfile.is_staff || userProfile.role_type === 'developer'">
        <button class="btn btn-outline" (click)="reloadPBXSystem()" placement="bottom" ngbTooltip="Restart the ConvoJet PBX system to reload with updated configurations.">Reload ConvoJet PBX</button>
      </div>
    </div>

  </div>

  <div class="header-background">
    <div class="header-logo">
      <img *ngIf="currentRoute === '/dashboard'" src="assets/images/label-icons/analytics_w.svg">
      <img *ngIf="currentRoute === '/dashboard/(shell:did)'" src="assets/images/label-icons/didconf_w.svg">
      <img *ngIf="currentRoute === '/dashboard/(shell:botexten)'" src="assets/images/label-icons/bot_w.svg">
      <img *ngIf="currentRoute === '/dashboard/(shell:agentexten)'" src="assets/images/label-icons/agent_w.svg">
      <img *ngIf="currentRoute === '/dashboard/(shell:settings)'" src="assets/images/label-icons/settings_w.svg">
      <img *ngIf="currentRoute === '/dashboard/(shell:logs)'" src="assets/images/label-icons/logs_w.svg">
      <img *ngIf="currentRoute === '/dashboard/(shell:usermanagement)'" src="assets/images/label-icons/usersettings_w.svg">
    </div>
  </div>
</div>
