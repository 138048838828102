<div class="content-header">
  <div class="title">
    User Management
  </div>
  <div class="subtitle">
    Add, Remove or Edit users in ConvoJet System.
  </div>
</div>

<div class="content">
  <div class="row" *ngIf="userProfile.organization_name.toLowerCase() === 'convojet'">
    <div class="col-sm-12">
      <div class="custom-tabs">
        <div class="tab-item" [ngClass]="{active: selectedTab==='users'}" (click)="selectedTab='users'">Users</div>
        <div class="tab-item" [ngClass]="{active: selectedTab==='organizations'}" (click)="selectedTab='organizations'">
          Organizations
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div *ngIf="selectedTab==='users'" class="card card-table">
        <div class="card-table-header">
          <div class="align-left">
            <div class="title tab">
              Users
            </div>
          </div>
          <div class="align-right">
            <button class="btn btn-primary" (click)="openCreateEditUser(null)"><i class="material-icons">add</i><span>Add New User</span>
            </button>
          </div>
        </div>

        <div class="card-table-body" *ngIf="users && users.length > 0">
          <table>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Username</th>
              <th>Role</th>
              <th *ngIf="userProfile.organization_name.toLowerCase() === 'convojet'">Organization</th>
              <th>Actions</th>
            </tr>
            <tr *ngFor="let user of users">
              <td>{{user.first_name}} {{(user.last_name? user.last_name : '')}}</td>
              <td>{{user.email}}</td>
              <td>{{user.username}}</td>
              <td>
                <span class="badge red" *ngIf="user.is_staff">Admin</span>
                <span class="badge green" *ngIf="!user.is_staff"
                      style="text-transform: capitalize;">{{user.role_type}}</span>
              </td>
              <td *ngIf="userProfile.organization_name.toLowerCase() === 'convojet'">
                {{getOrganizationName(user.organization)}}
              </td>
              <td>
                <div class="hover-button" placement="bottom" ngbTooltip="Change Password"
                     (click)="changeUserPassword(user)">
                  <i class="material-icons yellow">security</i>
                </div>
                <div class="hover-button" placement="bottom" ngbTooltip="Edit User Profile"
                     (click)="openCreateEditUser(user)">
                  <i class="material-icons blue">edit</i>
                </div>
                <div class="hover-button" placement="bottom" ngbTooltip="Delete User"
                     (click)="deleteUser(user)">
                  <i class="material-icons red">delete</i>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="empty-view" *ngIf="!users || users.length === 0">
          <div class="icon"><img src="assets/images/icons/empty.svg"></div>
          <div class="message">No Users Found!</div>
        </div>
      </div>
      <div *ngIf="selectedTab==='organizations'" class="card card-table">
        <div class="card-table-header">
          <div class="align-left">
            <div class="title tab">
              Organizations
            </div>
          </div>
          <div class="align-right">
            <button class="btn btn-primary" (click)="openCreateEditOrganization(null)"><i
              class="material-icons">add</i><span>Add New Organization</span>
            </button>
          </div>
        </div>

        <div class="card-table-body" *ngIf="organizations && organizations.length > 0">
          <table>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            <tr *ngFor="let org of organizations">
              <td>{{org.name}}</td>
              <td>
                <span class="badge green">Active</span>
              </td>
              <td>
                <div class="hover-button" placement="bottom" ngbTooltip="Edit Organization Details"
                     (click)="openCreateEditOrganization(org)" *ngIf="org.name.toLowerCase() !== 'convojet'">
                  <i class="material-icons blue">edit</i>
                </div>
                <div class="hover-button" placement="bottom" ngbTooltip="Delete Organization"
                     (click)="deleteOrganization(org)" *ngIf="org.name.toLowerCase() !== 'convojet'">
                  <i class="material-icons red">delete</i>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="empty-view" *ngIf="!organizations || organizations.length === 0">
          <div class="icon"><img src="assets/images/icons/empty.svg"></div>
          <div class="message">No Organizations Found!</div>
        </div>
      </div>
    </div>
  </div>

</div>
