import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingButtonDirective} from './loading-button.directive';
import {NgxSpinnerModule} from 'ngx-spinner';


@NgModule({
  declarations: [LoadingButtonDirective],
  exports: [LoadingButtonDirective],
  imports: [
    CommonModule,
    NgxSpinnerModule
  ]
})
export class CommonsModule {
}
