import {Component, OnInit} from '@angular/core';
import {SecurityService} from '../../commons/security.service';
import {Event as NavigationEvent, NavigationEnd, Router} from '@angular/router';
import {HttpService, METHOD} from '../../commons/http.service';
import {WarningMessageComponent} from '../../modals/warning-message/warning-message.component';
import {UtilsService} from '../../commons/utils.service';
import {ToastrService} from 'ngx-toastr';
import {CreateEditUserComponent} from '../../modals/create-edit-user/create-edit-user.component';
import {ChangePasswordComponent} from '../../modals/change-password/change-password.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public currentRoute: string;
  public userProfile: any;

  constructor(private securityService: SecurityService, private router: Router, private utilService: UtilsService,
              private httpService: HttpService, private toastr: ToastrService) {
    this.userProfile = this.securityService.userProfile;
    this.router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url;
      }
    });
  }

  ngOnInit() {
  }

  reloadPBXSystem() {
    const wModelRef = this.utilService.openModel(WarningMessageComponent,
      {
        okButton: 'Yes, Restart!',
        message: 'Are you sure you want to restart ConvoJet PBX System? This will discard all your ongoing calls.'
      });
    wModelRef.result.then((result) => {
      if (result === 'OK') {
        this.utilService.showLoading();
        this.httpService.request(HttpService.ENDPOINTS.restartPBX, METHOD.GET, {}, {}, {
          success: () => {
            this.toastr.success('PBX System reload initiated!');
            this.utilService.hideLoading();
          }, failure: () => {
            this.toastr.error('Failed to reload the PBX System!');
            this.utilService.hideLoading();
          }
        });
      }
    }, () => {
    });
  }

  openCreateEditUser(userDetails) {
    const data = {userDetails, organizations: null, isEdit: false, isFromEditProfile: true};
    if (userDetails) {
      data.isEdit = true;
    }
    const wModelRef = this.utilService.openModel(CreateEditUserComponent, data);
    wModelRef.result.then(() => {
      this.securityService.refreshProfile();
    }, () => {
    });
  }

  openChangePassword(userDetails) {
    const data = {userDetails, isFromEditProfile: true};
    const wModelRef = this.utilService.openModel(ChangePasswordComponent, data);
    wModelRef.result.then(() => {
    }, () => {
    });
  }

}
