import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  static EMAIL_PATTERN =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/;
  static PASSWORD_POLICY =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {}

  openModel(component: any, data: any) {
    const modalRef = this.modalService.open(component, {
      // scrollable: true,
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.data = data;
    return modalRef;
  }

  showLoading() {
    this.spinner.show("appSpinner", {
      type: "ball-grid-pulse",
      size: "medium",
      bdColor: "rgba(0,0,0,0)",
      color: "#009679",
      fullScreen: true,
    });
  }

  hideLoading() {
    this.spinner.hide("appSpinner");
  }

  validateEmail(email) {
    return email && email.match(UtilsService.EMAIL_PATTERN);
  }

  validatePassword(password) {
    return password && password.match(UtilsService.PASSWORD_POLICY);
  }

  getBotProperties() {
    return {
      name: null,
      extension: null,
      bot_config: {
        webhookUrl: null,
        token: null,
        bot_id: null,
        bot_name: null,
      },
      asr_config: {
        provider: null,
        private_key: null,
        client_email: null,
        projectId: null,
        useEnhancedSpeechModel: false,
        languageCode: "",
        apiKey: "",
        url: "",
        method: "",
      },
      tts_config: {
        provider: null,
        private_key: null,
        client_email: null,
        projectId: null,
        voice: {
          name: "en-US-Wavenet-C",
          languageCode: "en-US",
          ssmlGender: "FEMALE",
        },
        accessKeyId: null,
        secretAccessKey: null,
        region: "us-east-1",
        VoiceId: "Joanna",
        languageCode: null,
        apiKey: null,
        credential_type: null,
        url: null,
        method: null,
      },
      ext_settings: {
        enable_internal_calls: true,
        barge_in: false,
        enableKeyPad: false,
        maxDurationAfterKeyPress: 1,
        beepAfterRecord: false,
        maxSilenceSecondsForRecording: 1,
        numberOfRetries: 0,
        maxDurationSeconds: 0,
      },
    };
  }
}
