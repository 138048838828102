import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoginModule } from "./login/login.module";
import { CommonsModule } from "./commons/commons.module";
import { ModalsModule } from "./modals/modals.module";
import { RequestForAccessComponent } from "./modals/request-for-access/request-for-access.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule } from "@angular/forms";
import { MainModule } from "./main/main.module";
import {
  AdminAuthGuard,
  AuthGuard,
  DevAuthGuard,
  LoginPathGuard,
  SecurityService,
} from "./commons/security.service";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { WarningMessageComponent } from "./modals/warning-message/warning-message.component";
import { CreateEditUserComponent } from "./modals/create-edit-user/create-edit-user.component";
import { EditProfileComponent } from "./modals/edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./modals/change-password/change-password.component";
import { CreateEditOrganizationComponent } from "./modals/create-edit-organization/create-edit-organization.component";
import { CreateEditAgentComponent } from "./modals/create-edit-agent/create-edit-agent.component";
import { CreateEditDidComponent } from "./modals/create-edit-did/create-edit-did.component";
import { CreateEditBotComponent } from "./modals/create-edit-bot/create-edit-bot.component";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: "toast-top-center",
    }),
    NgbDropdownModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    LoginModule,
    CommonsModule,
    ModalsModule,
    MainModule,
  ],
  providers: [
    AuthGuard,
    LoginPathGuard,
    SecurityService,
    AdminAuthGuard,
    DevAuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
