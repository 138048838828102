import {Component, OnInit} from '@angular/core';
import {HttpService, METHOD} from '../../commons/http.service';
import {Router} from '@angular/router';
import {SecurityService} from '../../commons/security.service';
import {NgbTooltipConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  providers: [NgbTooltipConfig]
})
export class ShellComponent implements OnInit {

  constructor(private httpService: HttpService, private router: Router, private securityService: SecurityService,
              toolTipConfig: NgbTooltipConfig) {
    toolTipConfig.container = 'body';
  }

  ngOnInit() {
  }

}
