<div class="header">
  <img src="assets/images/logos/logo.svg">
</div>

<div class="body">
  <div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard'}">
    <div class="button" [routerLink]="['/dashboard']" placement="right" ngbTooltip="System Status & Analytics">
      <img *ngIf="currentRoute === '/dashboard'" src="assets/images/label-icons/analytics_a.svg">
      <img *ngIf="currentRoute !== '/dashboard'" src="assets/images/label-icons/analytics.svg">
    </div>
  </div>
  <div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard/(shell:did)'}"
       *ngIf="userProfile.is_staff || userProfile.role_type === 'developer'">
    <div class="button" [routerLink]="['/dashboard', {outlets:{shell:'did'}}]" placement="right" ngbTooltip="DID Configuration">
      <img *ngIf="currentRoute === '/dashboard/(shell:did)'" src="assets/images/label-icons/didconf_a.svg">
      <img *ngIf="currentRoute !== '/dashboard/(shell:did)'" src="assets/images/label-icons/didconf.svg">
    </div>
  </div>
  <div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard/(shell:botexten)'}"
       *ngIf="userProfile.is_staff || userProfile.role_type === 'developer'">
    <div class="button" [routerLink]="['/dashboard', {outlets:{shell:'botexten'}}]" placement="right" ngbTooltip="Bot Extensions">
      <img *ngIf="currentRoute === '/dashboard/(shell:botexten)'" src="assets/images/label-icons/bot_a.svg">
      <img *ngIf="currentRoute !== '/dashboard/(shell:botexten)'" src="assets/images/label-icons/bot.svg">
    </div>
  </div>
  <div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard/(shell:agentexten)'}"
       *ngIf="userProfile.is_staff || userProfile.role_type === 'developer'">
    <div class="button" [routerLink]="['/dashboard', {outlets:{shell:'agentexten'}}]" placement="right" ngbTooltip="Agent Extensions">
      <img *ngIf="currentRoute === '/dashboard/(shell:agentexten)'" src="assets/images/label-icons/agent_a.svg">
      <img *ngIf="currentRoute !== '/dashboard/(shell:agentexten)'" src="assets/images/label-icons/agent.svg">
    </div>
  </div>
  <!--<div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard/(shell:settings)'}">
    <div class="button" [routerLink]="['/dashboard', {outlets:{shell:'settings'}}]" placement="right" ngbTooltip="System Settings">
      <img *ngIf="currentRoute === '/dashboard/(shell:settings)'" src="assets/images/label-icons/settings_a.svg">
      <img *ngIf="currentRoute !== '/dashboard/(shell:settings)'" src="assets/images/label-icons/settings.svg">
    </div>
  </div>-->
  <div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard/(shell:logs)'}">
    <div class="button" [routerLink]="['/dashboard', {outlets:{shell:'logs'}}]" placement="right" ngbTooltip="Call Logs & Recordings">
      <img *ngIf="currentRoute === '/dashboard/(shell:logs)'" src="assets/images/label-icons/logs_a.svg">
      <img *ngIf="currentRoute !== '/dashboard/(shell:logs)'" src="assets/images/label-icons/logs.svg">
    </div>
  </div>
  <div class="sidebar-button" [ngClass]="{active: currentRoute === '/dashboard/(shell:usermanagement)'}" *ngIf="userProfile.is_staff">
    <div class="button" [routerLink]="['/dashboard', {outlets:{shell:'usermanagement'}}]" placement="right" ngbTooltip="User Management">
      <img *ngIf="currentRoute === '/dashboard/(shell:usermanagement)'" src="assets/images/label-icons/usersettings_a.svg">
      <img *ngIf="currentRoute !== '/dashboard/(shell:usermanagement)'" src="assets/images/label-icons/usersettings.svg">
    </div>
  </div>
</div>

<div class="footer">
  <div class="sidebar-button">
    <div class="button" placement="right" (click)="openLogoutWarning()" ngbTooltip="Sign out">
      <img src="assets/images/label-icons/logout.svg">
    </div>
  </div>
</div>
