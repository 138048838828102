import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import {HttpService, METHOD} from '../../commons/http.service';
import {UtilsService} from '../../commons/utils.service';
import {WarningMessageComponent} from '../../modals/warning-message/warning-message.component';
import {SecurityService} from '../../commons/security.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CreateEditAgentComponent} from '../../modals/create-edit-agent/create-edit-agent.component';
import {ChangePasswordComponent} from '../../modals/change-password/change-password.component';

@Component({
  selector: 'app-agentextension',
  templateUrl: './agentextension.component.html',
  styleUrls: ['./agentextension.component.scss']
})
export class AgentextensionComponent implements OnInit {
  public userProfile: any;
  public agentExtensions: any;

  constructor(private utilService: UtilsService, private httpService: HttpService,
              private securityService: SecurityService, private router: Router, private toastr: ToastrService) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    this.getAllAgentExtensions();
  }

  private getAllAgentExtensions() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id
    };
    this.httpService.request(HttpService.ENDPOINTS.agentList, METHOD.GET, params, payload, {
      success: (data) => {
        this.agentExtensions = data;
        this.utilService.hideLoading();
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      }
    });
  }

  openCreateEditAgentExtension(agentExtension) {
    const data = {agentExtension, isEdit: false};
    if (agentExtension) {
      data.isEdit = true;
    }
    const wModelRef = this.utilService.openModel(CreateEditAgentComponent, data);
    wModelRef.result.then(() => {
      this.getAllAgentExtensions();
    }, () => {
    });
  }

  deleteAgentExtension(agentExtension) {
    const wModelRef = this.utilService.openModel(WarningMessageComponent,
      {
        okButton: 'Yes, Delete!',
        message: 'Are you sure you want to delete this extension?'
      });
    wModelRef.result.then((result) => {
      if (result === 'OK') {
        this.utilService.showLoading();
        const params = {
          userId: this.userProfile.id,
          agentExtensionId: agentExtension.id
        };
        this.httpService.request(HttpService.ENDPOINTS.agentDetail, METHOD.DELETE, params, {}, {
          success: () => {
            this.utilService.hideLoading();
            this.getAllAgentExtensions();
            this.toastr.success('Agent Extension deleted Successfully!');
          },
          failure: (err) => {
            this.utilService.hideLoading();
            this.toastr.error(err.error);
          }
        });
      }
    }, () => {
    });
  }

  changeAgentPassword(agentExtension) {
    const data = {agentExtension, isAgentExtension: true};
    const wModelRef = this.utilService.openModel(ChangePasswordComponent, data);
    wModelRef.result.then(() => {
    }, () => {
    });
  }

}
