<div class="content-header">
  <div class="title">
    Agent Extensions
  </div>
  <div class="subtitle">
    Configure and manage your live agent extensions here.
  </div>
</div>

<div class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table">
        <div class="card-table-header">
          <div class="align-left">
            <div class="title">
              Live Agent Extensions
            </div>
          </div>
          <div class="align-right">
            <button class="btn btn-primary" (click)="openCreateEditAgentExtension(null)"><i
              class="material-icons">add</i><span>Add New Agent</span></button>
          </div>
        </div>
        <div class="card-table-body" *ngIf="agentExtensions && agentExtensions.length > 0">
          <table>
            <tr>
              <th>Name</th>
              <th>Extension Number</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
            <tr *ngFor="let agentExtension of agentExtensions">
              <td>{{agentExtension.name}}</td>
              <td>{{agentExtension.extension}}</td>
              <td>
                <span class="badge green capitalize">{{agentExtension.type}}</span>
              </td>
              <td>
                <div class="hover-button" placement="bottom" ngbTooltip="Change Password"
                     (click)="changeAgentPassword(agentExtension)">
                  <i class="material-icons yellow">security</i>
                </div>
                <div class="hover-button" placement="bottom" ngbTooltip="Edit Agent Extension"
                     (click)="openCreateEditAgentExtension(agentExtension)">
                  <i class="material-icons blue">edit</i>
                </div>
                <div class="hover-button" placement="bottom" ngbTooltip="Delete Agent Extension"
                     (click)="deleteAgentExtension(agentExtension)">
                  <i class="material-icons red">delete</i>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="empty-view" *ngIf="!agentExtensions || agentExtensions.length === 0">
          <div class="icon"><img src="assets/images/icons/empty.svg"></div>
          <div class="message">No Agent Extensions Configured!</div>
        </div>
      </div>
    </div>
  </div>

</div>
