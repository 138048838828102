import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UtilsService } from "../../commons/utils.service";
import { SecurityService } from "../../commons/security.service";
import { HttpService, METHOD } from "../../commons/http.service";
import * as _ from "lodash";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { SwiperDirective } from "ngx-swiper-wrapper-v-13";
import { SwiperOptions } from "swiper";
import { ScrollbarOptions } from "swiper/types/modules/scrollbar";

@Component({
  selector: "app-create-edit-did",
  templateUrl: "./create-edit-did.component.html",
  styleUrls: ["./create-edit-did.component.scss"],
})
export class CreateEditDidComponent implements OnInit {
  @Input() data;
  public modalTitle = "Configure DID Number";
  public didForm: any = {
    label: null,
    did_number: null,
    is_inbound: true,
    is_outbound: true,
    inbound_map: null,
    inbound_agent_type: null,
    agent_extension: null,
    bot_extension: null,
    data: {
      provider: null,
      customConfiguration: {
        username: null,
        secret: null,
        host: null,
        port: null,
      },
    },
  };
  public showFinishButton = false;
  public userProfile: any;

  private scrollbar: ScrollbarOptions = {
    el: ".swiper-scrollbar",
    hide: true,
    draggable: false,
  };

  public swiperConfig: SwiperOptions = {
    a11y: { enabled: true },
    direction: "horizontal",
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: this.scrollbar,
    navigation: true,
    pagination: false,
  };

  public inboundMapTypes = [
    { value: "bot", label: "Bot" },
    { value: "agent", label: "Agent" },
  ];
  public agentMapTypes = [
    { value: "internal_agents", label: "Internal Agents" },
    { value: "external_agent", label: "External Agent" },
  ];
  public agentExtensions = null;
  public botExtensions = null;
  public didProviders = [
    { value: "twilio", label: "Twilio" },
    { value: "voxvalley", label: "Voxvalley" },
    { value: "others", label: "Others" },
  ];

  @ViewChild(SwiperDirective) directiveRef?: SwiperDirective;

  constructor(
    public activeModal: NgbActiveModal,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private utilService: UtilsService,
    private httpService: HttpService
  ) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    this.agentExtensions = this.data.agentExtensions;
    this.botExtensions = this.data.botExtensions;

    if (this.data.isEdit) {
      this.modalTitle = "Edit DID Configuration";
      this.didForm = _.cloneDeep(this.data.didConf);
      this.didForm.inbound_map = _.find(this.inboundMapTypes, {
        value: this.didForm.inbound_map,
      });
      this.didForm.inbound_agent_type = _.find(this.agentMapTypes, {
        value: this.didForm.inbound_agent_type,
      });
      this.didForm.agent_extension = _.find(this.data.agentExtensions, {
        id: this.didForm.agent_extension,
      });
      this.didForm.bot_extension = _.find(this.data.botExtensions, {
        id: this.didForm.bot_extension,
      });
    }
  }

  private validate() {
    if (
      !this.didForm.label ||
      !this.didForm.did_number ||
      !this.didForm.inbound_map ||
      !this.didForm.data.provider ||
      !this.didForm.data.customConfiguration.host ||
      !this.didForm.data.customConfiguration.port ||
      (this.didForm.data.provider.value === "voxvalley" &&
        (!this.didForm.data.customConfiguration.username ||
          !this.didForm.data.customConfiguration.secret))
    ) {
      this.toastr.error("Please fill all the mandatory fields!");
      return;
    }
    if (this.didForm.inbound_map === "bot" && !this.didForm.bot_extension) {
      this.toastr.error(
        "DID Number should be mapped to one of the Bot Extension!"
      );
      return;
    }
    if (
      this.didForm.inbound_map === "bot" &&
      (!this.didForm.inbound_agent_type || !this.didForm.agent_extension)
    ) {
      this.toastr.error("DID Number should be mapped to Agent Extension!");
      return;
    }
    return true;
  }

  onPageChange(event) {
    this.showFinishButton = event === 2;
  }

  accept() {
    if (!this.validate()) {
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.didForm);
    payload.inbound_map = payload.inbound_map.value;
    if (payload.inbound_agent_type) {
      payload.inbound_agent_type = payload.inbound_agent_type.value;
    }
    if (payload.agent_extension) {
      payload.agent_extension = payload.agent_extension.id;
    }
    if (payload.bot_extension) {
      payload.bot_extension = payload.bot_extension.id;
    }
    const params: any = {
      userId: this.userProfile.id,
    };
    let method = METHOD.POST;
    let endpoint = HttpService.ENDPOINTS.didList;
    if (this.data.isEdit) {
      endpoint = HttpService.ENDPOINTS.didDetail;
      params.didId = this.data.didConf.id;
      method = METHOD.PUT;
    }
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.activeModal.close();
        this.utilService.hideLoading();
        if (this.data.isEdit) {
          this.toastr.success("DID Configuration is updated successfully!");
        } else {
          this.toastr.success("DID Configuration is created Successfully!");
        }
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      },
    });
  }

  nextSlide() {
    this.directiveRef.nextSlide();
  }
  previousSlide() {
    this.directiveRef.prevSlide();
  }
}
