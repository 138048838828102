import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {HttpService, METHOD} from './http.service';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  public userProfile: any = null;

  constructor(private httpService: HttpService, private toastr: ToastrService) {
    this.isLoggedIn();
  }

  public isLoggedIn() {
    let refreshProfile = false;
    if (!this.userProfile) {
      refreshProfile = true;
    }
    this.userProfile = window.localStorage.getItem('user');
    if (this.userProfile) {
      try {
        this.userProfile = JSON.parse(this.userProfile);
        if (refreshProfile) {
          this.refreshProfile();
        }
      } catch (e) {
        this.userProfile = null;
        this.logOut();
        return false;
      }
    }
    if (!this.userProfile || !this.userProfile.token) {
      return false;
    }
    return true;
  }

  public logOut() {
    this.userProfile = null;
    window.localStorage.removeItem('user');
  }

  public logIn(userProfile: any) {
    this.userProfile = userProfile;
    window.localStorage.setItem('user', JSON.stringify(userProfile));
  }

  public refreshProfile() {
    const params = {
      userId: this.userProfile.id,
      customerId: this.userProfile.id
    };
    this.httpService.request(HttpService.ENDPOINTS.userDetail, METHOD.GET, params, {}, {
      success: (data) => {
        this.userProfile = _.extend(this.userProfile, data);
        window.localStorage.setItem('user', JSON.stringify(this.userProfile));
      },
      failure: (error) => {
        this.toastr.error('Unable to fetch your Profile');
      }
    });
  }
}

@Injectable()
export class LoginPathGuard implements CanActivate {

  constructor(private securityService: SecurityService, private router: Router) {
  }

  canActivate() {
    if (!this.securityService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(protected securityService: SecurityService, protected router: Router) {
  }

  canActivate() {
    if (this.securityService.isLoggedIn()) {
      return true;
    }
    this.router.navigateByUrl('/dashboard/login');
    return false;
  }
}

@Injectable()
export class AdminAuthGuard extends AuthGuard implements CanActivate {
  constructor(securityService: SecurityService, router: Router) {
    super(securityService, router);
  }

  canActivate() {
    const isAllowed = super.canActivate();
    if (isAllowed && this.securityService.userProfile.is_staff) {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}

@Injectable()
export class DevAuthGuard extends AuthGuard implements CanActivate {
  constructor(securityService: SecurityService, router: Router) {
    super(securityService, router);
  }

  canActivate() {
    const isAllowed = super.canActivate();
    if (isAllowed && this.securityService.userProfile.role_type === 'developer') {
      return true;
    } else {
      this.router.navigate(['/dashboard']);
      return false;
    }
  }
}
