import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SecurityService} from '../../commons/security.service';
import {ToastrService} from 'ngx-toastr';
import {UtilsService} from '../../commons/utils.service';
import * as _ from 'lodash';
import {HttpService, METHOD} from '../../commons/http.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  @Input() data;
  public userForm: any = {
    oldPassword: null,
    password: null,
    confirmPassword: null
  };
  public userProfile: any;

  constructor(public activeModal: NgbActiveModal, private securityService: SecurityService, private toastr: ToastrService,
              private utilService: UtilsService, private httpService: HttpService) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    if (this.data.userDetails) {
      this.userForm = _.cloneDeep(this.data.userDetails);
    }
  }

  private validateOnSelfChange() {
    if (!this.userForm.password || !this.userForm.confirmPassword || !this.userForm.oldPassword) {
      this.toastr.error('Please fill all the mandatory fields!');
      return;
    }
    if (!this.utilService.validatePassword(this.userForm.password)) {
      this.toastr.error('Password policy does not match, Please choose other password!');
      return;
    }
    if (this.userForm.password !== this.userForm.confirmPassword) {
      this.toastr.error('Passwords does not match!');
      return;
    }
    return true;
  }

  private validateOnUserProfile() {
    if (!this.userForm.password || !this.userForm.confirmPassword) {
      this.toastr.error('Please fill all the mandatory fields!');
      return;
    }
    if (!this.utilService.validatePassword(this.userForm.password)) {
      this.toastr.error('Password policy does not match, Please choose other password!');
      return;
    }
    if (this.userForm.password !== this.userForm.confirmPassword) {
      this.toastr.error('Passwords does not match!');
      return;
    }
    return true;
  }

  accept() {
    if (this.data.isFromEditProfile && !this.validateOnSelfChange()) {
      return;
    }
    if ((!this.data.isFromEditProfile || this.data.isAgentExtension) && !this.validateOnUserProfile()) {
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.userForm);
    delete payload.confirmPassword;
    let params: any;
    const method = METHOD.PUT;
    let endpoint = HttpService.ENDPOINTS.changePassword;
    if (this.data.isAgentExtension) {
      endpoint = HttpService.ENDPOINTS.changeAgentPassword;
      params = {
        userId: this.userProfile.id,
        agentExtensionId: this.data.agentExtension.id
      };
    } else {
      params = {
        userId: this.userProfile.id,
        customerId: this.data.userDetails.id
      };
    }
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.activeModal.close();
        this.utilService.hideLoading();
        if (this.data.isFromEditProfile) {
          this.toastr.success('Your Password is updated Successfully!');
        } else if (this.data.isAgentExtension) {
          this.toastr.success('Agent Extension Password is updated Successfully!');
        } else {
          this.toastr.success('Password is updated Successfully!');
        }
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      }
    });
  }
}
