import { Component, Input, OnInit } from "@angular/core";
import * as _ from "lodash";
import { HttpService, METHOD } from "../../commons/http.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { UtilsService } from "../../commons/utils.service";
import { SecurityService } from "../../commons/security.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-create-edit-agent",
  templateUrl: "./create-edit-agent.component.html",
  styleUrls: ["./create-edit-agent.component.scss"],
})
export class CreateEditAgentComponent implements OnInit {
  @Input() data;
  public modalTitle = "Add New Agent Extension";
  public okButton = "Add";
  public agentForm: any = {
    data: {
      firstname: null,
      lastname: null,
      secret: null,
    },
    extension: null,
    type: null,
  };
  public confirmPassword: string;
  public userProfile: any;
  public agentTypes = [
    { value: "internal", label: "Internal Agent" },
    { value: "external", label: "External Agent" },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private utilService: UtilsService,
    private httpService: HttpService
  ) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    if (this.data.isEdit) {
      this.modalTitle = "Edit Agent Extension";
      this.okButton = "Save";
      this.agentForm = _.cloneDeep(this.data.agentExtension);
      this.agentForm.type = _.find(this.agentTypes, {
        value: this.agentForm.type,
      });
    }
  }

  private validateOnCreate() {
    if (
      !this.agentForm.data.firstname ||
      !this.agentForm.data.lastname ||
      !this.agentForm.type ||
      !this.agentForm.extension ||
      (this.agentForm.type.value === "internal" &&
        (!this.agentForm.data.secret || !this.confirmPassword))
    ) {
      this.toastr.error("Please fill all the mandatory fields!");
      return;
    }

    if (
      this.agentForm.type.value === "internal" &&
      this.confirmPassword !== this.agentForm.data.secret
    ) {
      this.toastr.error("Passwords does not match!");
      return;
    }
    if (
      this.agentForm.type.value === "internal" &&
      !this.utilService.validatePassword(this.agentForm.data.secret)
    ) {
      this.toastr.error(
        "Password policy does not match, Please choose other password!"
      );
      return;
    }

    if (this.agentForm.type.value === "external") {
      this.agentForm.data.secret = "external_agent";
    }
    return true;
  }

  private validateOnEdit() {
    if (
      !this.agentForm.data.firstname ||
      !this.agentForm.data.lastname ||
      !this.agentForm.type ||
      !this.agentForm.extension
    ) {
      this.toastr.error("Please fill all the mandatory fields!");
      return;
    }
    return true;
  }

  accept() {
    if (this.data.isEdit && !this.validateOnEdit()) {
      return;
    }
    if (!this.data.isEdit && !this.validateOnCreate()) {
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.agentForm);
    payload.type = payload.type.value;
    payload.name = payload.data.firstname + " " + payload.data.lastname;
    const params: any = {
      userId: this.userProfile.id,
    };
    let method = METHOD.POST;
    let endpoint = HttpService.ENDPOINTS.agentList;
    if (this.data.isEdit) {
      endpoint = HttpService.ENDPOINTS.agentDetail;
      params.agentExtensionId = this.data.agentExtension.id;
      method = METHOD.PUT;
    }
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.activeModal.close();
        this.utilService.hideLoading();
        if (this.data.isEdit) {
          this.toastr.success("Live Agent updated Successfully!");
        } else {
          this.toastr.success("Live Agent created Successfully!");
        }
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      },
    });
  }
}
