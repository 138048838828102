import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[app-loading-button]'
})
export class LoadingButtonDirective {

  private innerHTML: string;
  private loadingHTML: string = "<span class='loading-button'><img src='assets/images/loading-white.svg'></span>";

  constructor(private el: ElementRef) {
  }

  @Input('app-loading-button') set loadingIndicator(enableLoading: string) {
    if (!this.innerHTML) {
      this.innerHTML = this.el.nativeElement.innerText;
    }
    if (enableLoading == 'true') {
      this.el.nativeElement.innerHTML = this.loadingHTML;
    } else {
      this.el.nativeElement.innerHTML = this.innerHTML;
    }
  }

}
