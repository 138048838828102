<div class="content-header">
  <div class="title">
    System Status and Analytics
  </div>
  <div class="subtitle">
    Watch for live status of system and call analytics.
  </div>
</div>

<div class="content">
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="card live-status">
        <div class="details">
          <div class="value">10.2 %</div>
          <div class="title">
            CPU Usage
          </div>
        </div>
        <div class="chart" echarts [options]="eChartOptions">
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="card live-status">
        <div class="details">
          <div class="value">60.8 %</div>
          <div class="title">
            Memory Usage
          </div>
        </div>
        <div class="chart" echarts [options]="eChartOptions2"></div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="card live-status">
        <div class="details">
          <div class="value">20.2 %<span class="subvalue">340 MB of 1240 MB used</span></div>
          <div class="title">
            Disk Usage
          </div>
        </div>
        <div class="chart" echarts [options]="eChartOptions3"></div>
      </div>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-3">
      <div class="card live-status">
        <div class="details">
          <div class="value">0.2 %</div>
          <div class="title">
            Network Usage
          </div>
        </div>
        <div class="chart" echarts [options]="eChartOptions4"></div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="row-header">
        <div class="title align-left">
        </div>
        <div class="options align-right">
          <button class="btn btn-white-noborder"><i class="material-icons">filter_list</i><span>Filter</span></button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="card chart-card">
        <div class="details">
          <div class="title">
            InBound vs OutBound Calls
          </div>
        </div>
        <div class="chart full-width" echarts [options]="eChartOptions5"></div>
      </div>
    </div>
  </div>
</div>
