<div class="modal-header">
  <div class="modal-title">{{modalTitle}}</div>
  <div class="modal-title-image"><img src="assets/images/icons/organization.svg"></div>
  <div class="modal-subtitle">Create or edit the organizations in your ConvoJet system.</div>
  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')">close</i>
</div>
<div class="modal-body">
  <form>
    <div class="form-group col-md-12">
      <label>Name<sup class="mandatory-symbol">*</sup></label>
      <input type="text" name="name" [(ngModel)]="orgForm.name" class="form-control" placeholder="Organization's Name" required>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="accept()">{{okButton}}</button>
</div>
