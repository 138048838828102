import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShellComponent } from "./shell/shell.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { RouterModule } from "@angular/router";
import { NgbDropdownModule, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { HeaderComponent } from "./header/header.component";
import { AnalyticsComponent } from "./analytics/analytics.component";
import { DidconfComponent } from "./didconf/didconf.component";
import { BotextensionComponent } from "./botextension/botextension.component";
import { AgentextensionComponent } from "./agentextension/agentextension.component";
import { UsermanagementComponent } from "./usermanagement/usermanagement.component";
import { SettingsComponent } from "./settings/settings.component";
import { LogsComponent } from "./logs/logs.component";
import { NgxEchartsModule } from "ngx-echarts";
import { SwiperModule } from "ngx-swiper-wrapper-v-13";
import { FormsModule } from "@angular/forms";
import { UiSwitchModule } from "ngx-ui-switch";

@NgModule({
  declarations: [
    ShellComponent,
    SidebarComponent,
    HeaderComponent,
    AnalyticsComponent,
    DidconfComponent,
    BotextensionComponent,
    AgentextensionComponent,
    UsermanagementComponent,
    SettingsComponent,
    LogsComponent,
  ],
  imports: [
    SwiperModule,
    CommonModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    NgxEchartsModule.forRoot({
      echarts: () => import("echarts"),
    }),
    FormsModule,
    UiSwitchModule,
  ],
})
export class MainModule {}
