import { Component, OnInit } from "@angular/core";
// import {EChartsOption} from 'echarts';
import * as echarts from "echarts/lib/echarts";

@Component({
  selector: "app-analytics",
  templateUrl: "./analytics.component.html",
  styleUrls: ["./analytics.component.scss"],
})
export class AnalyticsComponent implements OnInit {
  public eChartOptions = {
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    xAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        show: true,
        inside: true,
        margin: 10,
        textStyle: {
          color: "black",
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
    },
    yAxis: {
      axisLabel: {
        inside: true,
        textStyle: {
          color: "#fff",
        },
      },
      type: "value",
      show: false,
    },
    series: [
      {
        data: [10, 50, 20, 25, 21, 48, 61],
        type: "line",
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: "#0BBF9C",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#0BBF9C",
            },
            {
              offset: 1,
              color: "#085847",
            },
          ]),
        },
      },
    ],
  };
  public eChartOptions2 = {
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    xAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        show: true,
        inside: true,
        margin: 10,
        textStyle: {
          color: "black",
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
    },
    yAxis: {
      axisLabel: {
        inside: true,
        textStyle: {
          color: "#fff",
        },
      },
      type: "value",
      show: false,
    },
    series: [
      {
        data: [90, 12, 40, 30, 40, 50, 80],
        type: "line",
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: "#8393ff",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#8393ff",
            },
            {
              offset: 1,
              color: "#6A75C9",
            },
          ]),
        },
      },
    ],
  };
  public eChartOptions3 = {
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    xAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        show: true,
        inside: true,
        margin: 10,
        textStyle: {
          color: "black",
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
    },
    yAxis: {
      axisLabel: {
        inside: true,
        textStyle: {
          color: "#fff",
        },
      },
      type: "value",
      show: false,
    },
    series: [
      {
        data: [12, 12, 13, 14, 14, 15, 18],
        type: "line",
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: "#d6c520",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#d6c520",
            },
            {
              offset: 1,
              color: "#908416",
            },
          ]),
        },
      },
    ],
  };
  public eChartOptions4 = {
    grid: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    xAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        show: true,
        inside: true,
        margin: 10,
        textStyle: {
          color: "black",
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      show: false,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
    },
    yAxis: {
      axisLabel: {
        inside: true,
        textStyle: {
          color: "#fff",
        },
      },
      type: "value",
      show: false,
    },
    series: [
      {
        data: [90, 10, 97, 50, 3, 20, 10],
        type: "line",
        smooth: true,
        showSymbol: false,
        itemStyle: {
          color: "#F36E12",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#F36E12",
            },
            {
              offset: 1,
              color: "#b8560f",
            },
          ]),
        },
      },
    ],
  };
  public eChartOptions5 = {
    grid: {
      top: 40,
      left: 50,
      right: 40,
      bottom: 50,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
      },
    },
    xAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        show: true,
        inside: false,
        margin: 10,
        textStyle: {
          color: "#d6d6d6",
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#d6d6d6",
        },
      },
      type: "category",
      boundaryGap: false,
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      show: true,
    },
    yAxis: {
      axisLabel: {
        showMinLabel: false,
        showMaxLabel: false,
        show: true,
        inside: false,
        margin: 10,
        textStyle: {
          color: "#d6d6d6",
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#d6d6d6",
        },
      },
      type: "value",
      boundaryGap: false,
      show: true,
    },
    series: [
      {
        data: [820, 932, 901, 934, 1290, 1330, 1320],
        type: "line",
        smooth: true,
        showSymbol: true,
        itemStyle: {
          color: "#0BBF9C",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#0BBF9C",
            },
            {
              offset: 1,
              color: "#FFF",
            },
          ]),
        },
      },
      {
        data: [300, 849, 360, 670, 890, 1400, 1560],
        type: "line",
        smooth: true,
        showSymbol: true,
        itemStyle: {
          color: "#8393ff",
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: "#8393ff",
            },
            {
              offset: 1,
              color: "#FFF",
            },
          ]),
        },
      },
    ],
  };

  constructor() {}

  ngOnInit() {}
}
