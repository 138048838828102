import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { RequestForAccessComponent } from "../../modals/request-for-access/request-for-access.component";
import { UtilsService } from "../../commons/utils.service";
import { HttpService, METHOD } from "../../commons/http.service";
import { SecurityService } from "../../commons/security.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import {
  SwiperAutoplayInterface,
  SwiperConfigInterface,
  SwiperPaginationInterface,
} from "ngx-swiper-wrapper-v-13";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginSlides: Array<any> = [
    {
      img: "assets/images/login-slides/1.svg",
      title: "PBX System",
      subtitle:
        "CovoJet is a standalone PBX system. You can configure your DID numbers and dial-plans from the dashboard",
    },
    {
      img: "assets/images/login-slides/2.svg",
      title: "Voice Bots",
      subtitle:
        "Configure bots for your calls and replace your traditional IVR with voice bots provided by Botjet.ai",
    },
    {
      img: "assets/images/login-slides/3.svg",
      title: "Live Agents",
      subtitle:
        "With ConvoJet, you can configure the live agents and handover calls from bot to agents",
    },
    {
      img: "assets/images/login-slides/4.svg",
      title: "Live Monitor",
      subtitle:
        "Now you can monitor the live system status and call analytics from dashboard",
    },
    {
      img: "assets/images/login-slides/5.svg",
      title: "Call Scheduling",
      subtitle: "You can broadcast or create scheduled calling to any numbers",
    },
  ];

  public isSigningIn = false;
  public loginForm: any = {
    username: null,
    password: null,
  };

  private swiperPagination: SwiperPaginationInterface = {
    el: ".swiper-pagination",
    clickable: true,
    hideOnClick: false,
  };

  private swiperAutoPlay: SwiperAutoplayInterface = {
    delay: 3000,
  };

  public swiperConfig: SwiperConfigInterface = {
    a11y: { enabled: true },
    direction: "horizontal",
    slidesPerView: 1,
    scrollbar: false,
    navigation: false,
    pagination: this.swiperPagination,
    autoplay: this.swiperAutoPlay,
  };

  constructor(
    private utilService: UtilsService,
    private httpService: HttpService,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    const showPassordButton = $(".show-password");
    const passwordField = showPassordButton.siblings("input");
    showPassordButton.off("click").on("click", () => {
      if (showPassordButton.html() === "visibility_off") {
        showPassordButton.html("visibility");
        passwordField.attr("type", "text");
      } else {
        showPassordButton.html("visibility_off");
        passwordField.attr("type", "password");
      }
    });
  }

  signIn() {
    this.isSigningIn = true;
    this.httpService.request(
      HttpService.ENDPOINTS.loginCheck,
      METHOD.POST,
      {},
      {
        username: this.loginForm.username,
        password: this.loginForm.password,
      },
      {
        success: (data) => {
          this.securityService.logIn(data);
          this.router.navigate(["/dashboard"]);
          this.isSigningIn = false;
        },
        failure: (err) => {
          this.toastr.error(err.error);
          this.isSigningIn = false;
        },
      }
    );
  }

  openRequestAccess() {
    this.utilService.openModel(RequestForAccessComponent, {});
  }
}
