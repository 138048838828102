<div class="content-header">
  <div class="title">DID Configuration</div>
  <div class="subtitle">
    Configure your public out-bound and in-bound DID phone numbers here.
  </div>
</div>

<div class="content">
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-table">
        <div class="card-table-header">
          <div class="align-left">
            <div class="title">DID Numbers</div>
          </div>
          <div class="align-right">
            <button
              class="btn btn-primary"
              (click)="openCreateEditDIDConf(null)"
            >
              <i class="material-icons">add</i><span>Add New</span>
            </button>
          </div>
        </div>

        <div class="card-table-body" *ngIf="didConfs && didConfs.length > 0">
          <table aria-describedby="DID Configuration Details">
            <tr>
              <th>Phone Number</th>
              <th>Label</th>
              <th>In-Bound</th>
              <th>Out-Bound</th>
              <th>Incoming To</th>
              <th>Actions</th>
            </tr>
            <tr *ngFor="let didConf of didConfs">
              <td>{{ didConf.did_number }}</td>
              <td>{{ didConf.label }}</td>
              <td>
                <span
                  class="badge"
                  [ngClass]="{
                    green: didConf.is_inbound,
                    red: !didConf.is_inbound
                  }"
                  >{{ didConf.is_inbound ? "Active" : "In-Active" }}</span
                >
              </td>
              <td>
                <span
                  class="badge"
                  [ngClass]="{
                    green: didConf.is_outbound,
                    red: !didConf.is_outbound
                  }"
                  >{{ didConf.is_outbound ? "Active" : "In-Active" }}</span
                >
              </td>
              <td>
                <span class="inline-block map-icon">
                  <img
                    *ngIf="didConf.inbound_map === 'bot'"
                    src="assets/images/icons/botjet.svg"
                    alt="botjet"
                  />
                  <img
                    *ngIf="didConf.inbound_map === 'agent'"
                    src="assets/images/icons/agent.svg"
                    alt="agent"
                  />
                </span>
                <span class="map-name">{{ getIncomingMapName(didConf) }}</span>
              </td>
              <td>
                <div
                  class="hover-button"
                  placement="bottom"
                  ngbTooltip="Edit Agent Extension"
                  (click)="openCreateEditDIDConf(didConf)"
                >
                  <i class="material-icons blue">edit</i>
                </div>
                <div
                  class="hover-button"
                  placement="bottom"
                  ngbTooltip="Delete Agent Extension"
                  (click)="deleteDIDConf(didConf)"
                >
                  <i class="material-icons red">delete</i>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="empty-view" *ngIf="!didConfs || didConfs.length === 0">
          <div class="icon">
            <img src="assets/images/icons/empty.svg" alt="emptyImage" />
          </div>
          <div class="message">No Phone Numbers Configured!</div>
        </div>
      </div>
    </div>
  </div>
</div>
