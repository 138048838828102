<div class="modal-header">
  <div class="modal-title-image-float"><img src="{{data.icon}}"></div>
  <div class="modal-title">{{data.title}}</div>
  <div class="modal-subtitle">{{data.subtitle}}</div>
  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')">close</i>
</div>
<div class="modal-body">
  <div class="message">{{data.message}}</div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('CANCEL')">{{data.cancelButton}}</button>
  <button type="button" class="btn btn-red" (click)="activeModal.close('OK')">{{data.okButton}}</button>
</div>
