import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UtilsService } from "../../commons/utils.service";
import { HttpService, METHOD } from "../../commons/http.service";
import { SecurityService } from "../../commons/security.service";
import { WarningMessageComponent } from "../../modals/warning-message/warning-message.component";
import { CreateEditBotComponent } from "../../modals/create-edit-bot/create-edit-bot.component";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";

@Component({
  selector: "app-botextension",
  templateUrl: "./botextension.component.html",
  styleUrls: ["./botextension.component.scss"],
})
export class BotextensionComponent implements OnInit {
  public userProfile: any;
  public botExtensions: any;
  public selectedBot: any;
  public botForm: any;

  public asrProviders = [
    { value: "google", label: "Google Speech API" },
    { value: "deepgram", label: "Deepgram Speech API" },
  ];
  public ttsProviders = [
    { value: "google", label: "Google Speech API" },
    { value: "polly", label: "Amazon Polly" },
  ];

  public googlettsCredentials = [
    { value: "rest", label: "Rest API" },
    { value: "service", label: "Service Account" },
  ];

  public apiMethods = [
    { value: "post", label: "POST" },
    { value: "get", label: "GET" },
  ];

  constructor(
    private utilService: UtilsService,
    private httpService: HttpService,
    private securityService: SecurityService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.userProfile = this.securityService.userProfile;
    this.botForm = utilService.getBotProperties();
  }

  ngOnInit() {
    this.getAllBotExtensions();
  }

  private getAllBotExtensions() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id,
    };
    this.httpService.request(
      HttpService.ENDPOINTS.botList,
      METHOD.GET,
      params,
      payload,
      {
        success: (data) => {
          this.botExtensions = data;
          this.utilService.hideLoading();
        },
        failure: (err) => {
          this.utilService.hideLoading();
          this.toastr.error(err.error);
        },
      }
    );
  }

  openCreateBotExtension() {
    const data = {};
    const wModelRef = this.utilService.openModel(CreateEditBotComponent, data);
    wModelRef.result.then(
      () => {
        this.getAllBotExtensions();
      },
      () => {}
    );
  }

  deleteBotExtension(botExtension) {
    const wModelRef = this.utilService.openModel(WarningMessageComponent, {
      okButton: "Yes, Delete!",
      message: "Are you sure you want to delete this Bot Extension?",
    });
    wModelRef.result.then(
      (result) => {
        if (result === "OK") {
          this.utilService.showLoading();
          const params = {
            userId: this.userProfile.id,
            botExtensionId: botExtension.id,
          };
          this.httpService.request(
            HttpService.ENDPOINTS.botDetail,
            METHOD.DELETE,
            params,
            {},
            {
              success: () => {
                this.utilService.hideLoading();
                this.getAllBotExtensions();
                this.clearBotSelection();
                this.toastr.success("Bot Extension deleted Successfully!");
              },
              failure: (err) => {
                this.utilService.hideLoading();
                this.toastr.error(err.error);
              },
            }
          );
        }
      },
      () => {}
    );
  }

  private validate() {
    if (
      !this.botForm.name ||
      !this.botForm.extension ||
      !this.botForm.bot_config.webhookUrl ||
      !this.botForm.bot_config.token ||
      !this.botForm.asr_config.provider ||
      !this.botForm.tts_config.provider
    ) {
      this.toastr.error("Please fill all the mandatory fields!");
      return;
    }
    if (
      this.botForm.asr_config.provider.value === "google" &&
      (!this.botForm.asr_config.private_key ||
        !this.botForm.asr_config.client_email ||
        !this.botForm.asr_config.projectId ||
        !this.botForm.asr_config.languageCode)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Google's Speech Recognition Engine!"
      );
      return;
    }
    if (
      this.botForm.asr_config.provider.value === "deepgram" &&
      (!this.botForm.asr_config.url || !this.botForm.asr_config.apiKey)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Deepgram Speech Recognition Engine!"
      );
      return;
    }
    if (
      this.botForm.tts_config.provider.value === "google" &&
      this.botForm.tts_config.credential_type.value === "rest" &&
      (!this.botForm.tts_config.apiKey ||
        !this.botForm.tts_config.url ||
        !this.botForm.tts_config.method.value ||
        !this.botForm.tts_config.voice.name ||
        !this.botForm.tts_config.voice.languageCode ||
        !this.botForm.tts_config.voice.ssmlGender)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Google's Text to Speech Engine!"
      );
      return;
    }
    if (
      this.botForm.tts_config.provider.value === "google" &&
      this.botForm.tts_config.credential_type.value === "service" &&
      (!this.botForm.tts_config.private_key ||
        !this.botForm.tts_config.client_email ||
        !this.botForm.tts_config.projectId ||
        !this.botForm.tts_config.voice.name ||
        !this.botForm.tts_config.voice.languageCode ||
        !this.botForm.tts_config.voice.ssmlGender)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Google's Text to Speech Engine!"
      );
      return;
    }
    if (
      this.botForm.tts_config.provider.value === "polly" &&
      (!this.botForm.tts_config.accessKeyId ||
        !this.botForm.tts_config.secretAccessKey ||
        !this.botForm.tts_config.region ||
        !this.botForm.tts_config.VoiceId ||
        !this.botForm.tts_config.languageCode)
    ) {
      this.toastr.error(
        "Please provide a valid configuration of Amazon Polly's Text to Speech Engine!"
      );
      return;
    }
    return true;
  }

  updateBotExtension(bot) {
    if (!this.validate()) {
      return;
    }
    this.utilService.showLoading();
    const payload = _.cloneDeep(this.botForm);
    payload.tts_config.provider = payload.tts_config.provider.value;
    payload.asr_config.provider = payload.asr_config.provider.value;
    const params: any = {
      userId: this.userProfile.id,
      botExtensionId: bot.id,
    };
    const method = METHOD.PUT;
    const endpoint = HttpService.ENDPOINTS.botDetail;
    this.httpService.request(endpoint, method, params, payload, {
      success: () => {
        this.utilService.hideLoading();
        this.getAllBotExtensions();
        this.toastr.success("Bot Extension is updated Successfully!");
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      },
    });
  }

  selectBot(bot) {
    this.selectedBot = bot;
    this.botForm = _.cloneDeep(this.selectedBot);
    this.botForm.tts_config.provider = _.find(this.ttsProviders, {
      value: this.botForm.tts_config.provider,
    });
    this.botForm.asr_config.provider = _.find(this.asrProviders, {
      value: this.botForm.asr_config.provider,
    });
  }

  clearBotSelection() {
    this.selectedBot = null;
    this.botForm = this.utilService.getBotProperties();
  }

  revertBotChanges() {
    const wModelRef = this.utilService.openModel(WarningMessageComponent, {
      okButton: "Yes",
      message: "You will loose the changes made now. Do you want to proceed?",
    });
    wModelRef.result.then(
      (result) => {
        if (result === "OK") {
          this.botForm = _.cloneDeep(this.selectedBot);
          this.botForm.tts_config.provider = _.find(this.ttsProviders, {
            value: this.botForm.tts_config.provider,
          });
          this.botForm.asr_config.provider = _.find(this.asrProviders, {
            value: this.botForm.asr_config.provider,
          });
        }
      },
      () => {}
    );
  }

  removeUnwantedData(data: any) {
    if (data.value == "rest") {
      this.botForm.tts_config.projectId = null;
      this.botForm.tts_config.client_email = null;
      this.botForm.tts_config.private_key = null;
      this.botForm.tts_config.languageCode = null;
    }

    if (data.value == "service") {
      this.botForm.tts_config.apiKey = null;
      this.botForm.tts_config.languageCode = null;
      this.botForm.tts_config.url = null;
    }
  }

  removeTTSData(data: any) {
    if (data.value == "google") {
      this.botForm.tts_config.accessKeyId = null;
      this.botForm.tts_config.secretAccessKey = null;
      this.botForm.tts_config.languageCode = null;
    }
    if (data.value == "polly") {
      this.botForm.tts_config.projectId = null;
      this.botForm.tts_config.client_email = null;
      this.botForm.tts_config.private_key = null;
      this.botForm.tts_config.languageCode = null;
      this.botForm.tts_config.apiKey = null;
      this.botForm.tts_config.url = null;
    }
  }
}
