import {Component, OnInit} from '@angular/core';
import {SecurityService} from '../../commons/security.service';
import {Router} from '@angular/router';
import {HttpService, METHOD} from '../../commons/http.service';
import {UtilsService} from '../../commons/utils.service';
import {ToastrService} from 'ngx-toastr';
import {CreateEditUserComponent} from '../../modals/create-edit-user/create-edit-user.component';
import * as _ from 'lodash';
import {CreateEditOrganizationComponent} from '../../modals/create-edit-organization/create-edit-organization.component';
import {WarningMessageComponent} from '../../modals/warning-message/warning-message.component';
import {ChangePasswordComponent} from '../../modals/change-password/change-password.component';

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.scss']
})
export class UsermanagementComponent implements OnInit {
  public userProfile: any;
  public organizations: any;
  public users: any;
  public selectedTab = 'users';

  constructor(private utilService: UtilsService, private httpService: HttpService,
              private securityService: SecurityService, private router: Router, private toastr: ToastrService) {
    this.userProfile = this.securityService.userProfile;
  }

  ngOnInit() {
    if (this.userProfile.organization_name.toLowerCase() === 'convojet') {
      this.getAllOrganizations();
    }
    this.getAllUsers();
  }

  private getAllOrganizations() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id
    };
    this.httpService.request(HttpService.ENDPOINTS.orgList, METHOD.GET, params, payload, {
      success: (data) => {
        this.organizations = data;
        this.utilService.hideLoading();
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      }
    });
  }

  private getAllUsers() {
    this.utilService.showLoading();
    const payload = {};
    const params = {
      userId: this.userProfile.id,
      get_all: false
    };
    if (this.userProfile.organization_name.toLowerCase() === 'convojet') {
      params.get_all = true;
    }
    this.httpService.request(HttpService.ENDPOINTS.userList, METHOD.GET, params, payload, {
      success: (data) => {
        data = _.sortBy(data, ['organization']);
        this.users = data;
        this.utilService.hideLoading();
      },
      failure: (err) => {
        this.utilService.hideLoading();
        this.toastr.error(err.error);
      }
    });
  }

  openCreateEditUser(userDetails) {
    const data = {userDetails, organizations: this.organizations, isEdit: false};
    if (userDetails) {
      data.isEdit = true;
    }
    const wModelRef = this.utilService.openModel(CreateEditUserComponent, data);
    wModelRef.result.then(() => {
      this.getAllUsers();
    }, () => {
    });
  }

  openCreateEditOrganization(organization) {
    const data = {organization, isEdit: false};
    if (organization) {
      data.isEdit = true;
    }
    const wModelRef = this.utilService.openModel(CreateEditOrganizationComponent, data);
    wModelRef.result.then(() => {
      this.getAllOrganizations();
    }, () => {
    });
  }

  changeUserPassword(userDetails) {
    const data = {userDetails, isFromEditProfile: false};
    const wModelRef = this.utilService.openModel(ChangePasswordComponent, data);
    wModelRef.result.then(() => {
    }, () => {
    });
  }

  deleteUser(user) {
    const wModelRef = this.utilService.openModel(WarningMessageComponent,
      {
        okButton: 'Yes, Delete!',
        message: 'Are you sure you want to delete user \'' + user.first_name + ' ' + (user.last_name ? user.last_name : '') + '\'?'
      });
    wModelRef.result.then((result) => {
      if (result === 'OK') {
        this.utilService.showLoading();
        const params = {
          userId: this.userProfile.id,
          customerId: user.id
        };
        this.httpService.request(HttpService.ENDPOINTS.userDetail, METHOD.DELETE, params, {}, {
          success: () => {
            this.utilService.hideLoading();
            this.getAllUsers();
            this.toastr.success('User deleted Successfully!');
          },
          failure: (err) => {
            this.utilService.hideLoading();
            this.toastr.error(err.error);
          }
        });
      }
    }, () => {
    });
  }

  deleteOrganization(organization) {
    const wModelRef = this.utilService.openModel(WarningMessageComponent,
      {okButton: 'Yes, Delete!', message: 'Are you sure you want to delete \'' + organization.name + '\' Organization?'});
    wModelRef.result.then((result) => {
      if (result === 'OK') {
        this.utilService.showLoading();
        const params = {
          userId: this.userProfile.id,
          orgId: organization.id
        };
        this.httpService.request(HttpService.ENDPOINTS.orgDetail, METHOD.DELETE, params, {}, {
          success: () => {
            this.utilService.hideLoading();
            this.getAllOrganizations();
            this.toastr.success('Organization deleted Successfully!');
          },
          failure: (err) => {
            this.utilService.hideLoading();
            this.toastr.error(err.error);
          }
        });
      }
    }, () => {
    });
  }

  getOrganizationName(orgId) {
    if (this.organizations) {
      const org = _.find(this.organizations, {id: orgId});
      if (org) {
        return org.name;
      }
    }
    return 'N/A';
  }

}
