<div class="content-header">
  <div class="title">Bot Extensions</div>
  <div class="subtitle">
    Configure and manage your bots and its ASR, TTS and general settings here.
  </div>
</div>

<div class="content bot_extensions">
  <div class="row scrollable">
    <div class="card-wrapper">
      <div
        class="card bot-card placeholder"
        placement="bottom"
        (click)="openCreateBotExtension()"
        ngbTooltip="Create New Bot Extension"
      >
        <div class="add-icon"><i class="material-icons">add</i></div>
      </div>
    </div>
    <div *ngFor="let bot of botExtensions" class="card-wrapper">
      <div
        class="card bot-card"
        (click)="selectBot(bot)"
        [ngClass]="{
          selected: selectedBot ? selectedBot.id === bot.id : false
        }"
      >
        <div class="bot-details">
          <div class="bot-icon">
            <img src="assets/images/icons/botjet.svg" />
          </div>
          <div class="bot-exten-detail">
            <div class="subtitle">
              {{ bot.extension }}
            </div>
            <div class="title">
              {{ bot.name }}
            </div>
          </div>
        </div>

        <div class="bot-details-footer">
          <div class="title">0</div>
          <div class="subtitle">/ Sessions</div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row"
    *ngIf="botExtensions && botExtensions.length > 0 && !selectedBot"
  >
    <div class="empty-view select-view offset-top-8">
      <div class="icon"><img src="assets/images/icons/select.svg" /></div>
      <div class="message">
        Select a bot extension to view or edit its configuration!
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!botExtensions || botExtensions.length === 0">
    <div class="empty-view offset-top-8">
      <div class="icon"><img src="assets/images/icons/botjet.svg" /></div>
      <div class="message">Click below to create your first Bot Extension!</div>
      <div class="button">
        <button class="btn btn-primary" (click)="openCreateBotExtension()">
          Create Now
        </button>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="selectedBot">
    <div class="col-sm-6">
      <div class="card height-400">
        <div class="card-header">{{ selectedBot.name }}'s Configuration</div>
        <div class="card-body">
          <form>
            <div class="form-group col-md-12">
              <label>Name<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="name"
                [(ngModel)]="botForm.name"
                class="form-control"
                placeholder="Name of the Bot"
                required
              />
            </div>
            <div class="form-group col-md-12">
              <label
                >Extension Number<sup class="mandatory-symbol">*</sup></label
              >
              <input
                type="text"
                name="extension"
                [(ngModel)]="botForm.extension"
                class="form-control"
                placeholder="Extension number to be assigned for the Bot"
                required
              />
            </div>
            <div class="form-group col-md-12">
              <label
                >Bot Webhook URL<sup class="mandatory-symbol">*</sup></label
              >
              <input
                type="text"
                name="webhookUrl"
                [(ngModel)]="botForm.bot_config.webhookUrl"
                class="form-control"
                placeholder="Webhook URL provided by Webhook channel in BotJet Dashboard"
                required
              />
            </div>
            <div class="form-group col-md-12">
              <label>Token<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="token"
                [(ngModel)]="botForm.bot_config.token"
                class="form-control"
                placeholder="Token provided by Webhook channel in BotJet Dashboard"
                required
              />
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="card height-400">
        <div class="card-header">
          {{ selectedBot.name }}'s Interaction Settings
        </div>
        <div class="card-body">
          <form>
            <div class="form-group col-md-12 position-relative">
              <label>Allow calls from Internal Agents</label>
              <div class="check-box">
                <ui-switch
                  size="small"
                  color="#0BBF9C"
                  name="enable_internal_calls"
                  [(ngModel)]="botForm.ext_settings.enable_internal_calls"
                ></ui-switch>
              </div>
            </div>

            <div class="form-group col-md-12 position-relative">
              <label>Enable Key Pad Interaction</label>
              <div class="check-box">
                <ui-switch
                  size="small"
                  color="#0BBF9C"
                  name="enableKeyPad"
                  [(ngModel)]="botForm.ext_settings.enableKeyPad"
                ></ui-switch>
              </div>
            </div>

            <div class="form-group col-md-12 position-relative">
              <label>Enable Barge In</label>
              <div class="check-box">
                <ui-switch
                  size="small"
                  color="#0BBF9C"
                  name="barge_in"
                  [(ngModel)]="botForm.ext_settings.barge_in"
                ></ui-switch>
              </div>
            </div>

            <div class="form-group col-md-12 position-relative">
              <label>Enable Beep after listening to user input</label>
              <div class="check-box">
                <ui-switch
                  size="small"
                  color="#0BBF9C"
                  name="beepAfterRecord"
                  [(ngModel)]="botForm.ext_settings.beepAfterRecord"
                ></ui-switch>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label
                >Duration to wait after keypad press<sup
                  class="mandatory-symbol"
                  >*</sup
                ></label
              >
              <input
                type="number"
                name="maxDurationAfterKeyPress"
                [(ngModel)]="botForm.ext_settings.maxDurationAfterKeyPress"
                class="form-control"
                placeholder="Maximum time to wait to listening further key press"
                required
              />
            </div>
            <div class="form-group col-md-6">
              <label
                >Duration of silence to finish user input<sup
                  class="mandatory-symbol"
                  >*</sup
                ></label
              >
              <input
                type="number"
                name="maxSilenceSecondsForRecording"
                [(ngModel)]="botForm.ext_settings.maxSilenceSecondsForRecording"
                class="form-control"
                placeholder="Silence duration"
                required
              />
            </div>

            <div class="form-group col-md-6">
              <label
                >Maximum Duration to record user input<sup
                  class="mandatory-symbol"
                  >*</sup
                ></label
              >
              <input
                type="number"
                name="maxDurationSeconds"
                [(ngModel)]="botForm.ext_settings.maxDurationSeconds"
                class="form-control"
                placeholder="0 means unlimited"
                required
              />
            </div>
            <div class="form-group col-md-6">
              <label
                >Maximum retries to wait for user input<sup
                  class="mandatory-symbol"
                  >*</sup
                ></label
              >
              <input
                type="number"
                name="numberOfRetries"
                [(ngModel)]="botForm.ext_settings.numberOfRetries"
                class="form-control"
                placeholder="Call will be hanged up after the number of retries"
                required
              />
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="card height-400">
        <div class="card-header">
          {{ selectedBot.name }}'s Speech Recognition Settings
        </div>
        <div class="card-body">
          <form>
            <div class="form-group col-md-12">
              <label
                >Speech Recognition Provider<sup class="mandatory-symbol"
                  >*</sup
                ></label
              >
              <div ngbDropdown container="body">
                <div
                  ngbDropdownToggle
                  id="asr-provider-dropdown"
                  class="form-control dropdown-control"
                >
                  <div
                    class="placeholder"
                    [ngClass]="{ selected: !!botForm.asr_config.provider }"
                  >
                    <span *ngIf="!botForm.asr_config.provider"
                      >Select ASR Provider</span
                    >
                    <span *ngIf="botForm.asr_config.provider">{{
                      botForm.asr_config.provider.label
                    }}</span>
                    <i class="material-icons">expand_more</i>
                  </div>
                </div>
                <div
                  ngbDropdownMenu
                  aria-labelledby="asr-provider-dropdown"
                  class="modal-dropdown"
                >
                  <div
                    ngbDropdownItem
                    class="dropdown-btn"
                    *ngFor="let type of asrProviders"
                    (click)="botForm.asr_config.provider = type"
                  >
                    {{ type.label }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'google'
              "
            >
              <label>Language Code<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="languageCode"
                [(ngModel)]="botForm.asr_config.languageCode"
                class="form-control"
                placeholder="Language Code for Recognition"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'google'
              "
            >
              <label>Project ID<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="projectId"
                [(ngModel)]="botForm.asr_config.projectId"
                class="form-control"
                placeholder="Google Project ID"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'google'
              "
            >
              <label>Client Email<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="client_email"
                [(ngModel)]="botForm.asr_config.client_email"
                class="form-control"
                placeholder="Client Email Provided in JSON"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'google'
              "
            >
              <label>Private Key<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="private_key"
                [(ngModel)]="botForm.asr_config.private_key"
                class="form-control"
                placeholder="Private Key Provided in JSON"
                required
              />
            </div>

            <div
              class="form-group col-md-12 position-relative"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'google'
              "
            >
              <label>Use Enhanced Google Speech Models</label>
              <div class="check-box">
                <ui-switch
                  size="small"
                  color="#0BBF9C"
                  name="useEnhancedSpeechModel"
                  [(ngModel)]="botForm.asr_config.useEnhancedSpeechModel"
                ></ui-switch>
              </div>
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'deepgram'
              "
            >
              <label>Url<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="projectId"
                [(ngModel)]="botForm.asr_config.url"
                class="form-control"
                placeholder="Webhook Url"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.asr_config.provider &&
                botForm.asr_config.provider.value === 'deepgram'
              "
            >
              <label>API Key<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="private_key"
                [(ngModel)]="botForm.asr_config.private_key"
                class="form-control"
                placeholder="API Key Provided in JSON"
                required
              />
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="card height-400">
        <div class="card-header">
          {{ selectedBot.name }}'s Text To Speech Settings
        </div>
        <div class="card-body">
          <form class="w-100">
            <div class="form-group col-md-12">
              <label
                >Text to Speech Provider<sup class="mandatory-symbol"
                  >*</sup
                ></label
              >
              <div ngbDropdown container="body">
                <div
                  ngbDropdownToggle
                  id="tts-provider-dropdown"
                  class="form-control dropdown-control"
                >
                  <div
                    class="placeholder"
                    [ngClass]="{ selected: !!botForm.tts_config.provider }"
                  >
                    <span *ngIf="!botForm.tts_config.provider"
                      >Select ASR Provider</span
                    >
                    <span *ngIf="botForm.tts_config.provider">{{
                      botForm.tts_config.provider.label
                    }}</span>
                    <i class="material-icons">expand_more</i>
                  </div>
                </div>
                <div
                  ngbDropdownMenu
                  aria-labelledby="tts-provider-dropdown"
                  class="modal-dropdown"
                >
                  <div
                    ngbDropdownItem
                    class="dropdown-btn"
                    *ngFor="let type of ttsProviders"
                    (click)="
                      botForm.tts_config.provider = type; removeTTSData(type)
                    "
                  >
                    {{ type.label }}
                  </div>
                </div>
              </div>
              <div
                ngbDropdown
                container="body"
                *ngIf="botForm.tts_config.provider.value == 'google'"
              >
                <div
                  ngbDropdownToggle
                  id="tts-provider-dropdown"
                  class="form-control dropdown-control"
                >
                  <div
                    class="placeholder"
                    [ngClass]="{ selected: !!botForm.tts_config.provider }"
                  >
                    <span *ngIf="!botForm.tts_config.credential_type"
                      >Select Google Credentials</span
                    >
                    <span *ngIf="botForm.tts_config.credential_type">{{
                      botForm.tts_config.credential_type.label
                    }}</span>
                    <i class="material-icons">expand_more</i>
                  </div>
                </div>
                <div
                  ngbDropdownMenu
                  aria-labelledby="tts-provider-dropdown"
                  class="modal-dropdown"
                >
                  <div
                    ngbDropdownItem
                    class="dropdown-btn"
                    *ngFor="let type of googlettsCredentials"
                    (click)="
                      botForm.tts_config.credential_type = type;
                      removeUnwantedData(type)
                    "
                  >
                    {{ type.label }}
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'service'
              "
            >
              <label>Project ID<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="ttsProjectId"
                [(ngModel)]="botForm.tts_config.projectId"
                class="form-control"
                placeholder="Google Project ID"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'service'
              "
            >
              <label>Client Email<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="tts_client_email"
                [(ngModel)]="botForm.tts_config.client_email"
                class="form-control"
                placeholder="Client Email Provided in JSON"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'service'
              "
            >
              <label>Private Key<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="private_key"
                [(ngModel)]="botForm.tts_config.private_key"
                class="form-control"
                placeholder="Private Key Provided in JSON"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'service'
              "
            >
              <label>Voice Name<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="google_voice_name"
                [(ngModel)]="botForm.tts_config.voice.name"
                class="form-control"
                placeholder="Private The Voice Name From Google"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'service'
              "
            >
              <label>SSML Gender<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="google_ssmlGender"
                [(ngModel)]="botForm.tts_config.voice.ssmlGender"
                class="form-control"
                placeholder="MALE or FEMALE"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'service'
              "
            >
              <label>Language Code<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="ttslanguageCode"
                [(ngModel)]="botForm.tts_config.languageCode"
                class="form-control"
                placeholder="Language Code for Google TTS"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'rest'
              "
            >
              <label>Url<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="tts_url"
                [(ngModel)]="botForm.tts_config.url"
                class="form-control"
                placeholder="Webhook Url"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'rest'
              "
            >
              <label>Api Key<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="API Key"
                [(ngModel)]="botForm.tts_config.apiKey"
                class="form-control"
                placeholder="API Key"
                required
              />
            </div>

            <div
              ngbDropdown
              container="body"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'rest'
              "
            >
              <div
                ngbDropdownToggle
                id="tts-provider-dropdown"
                class="form-control dropdown-control"
              >
                <div
                  class="placeholder"
                  [ngClass]="{ selected: !!botForm.tts_config.method }"
                >
                  <span *ngIf="!botForm.tts_config.method">Select Method</span>
                  <span *ngIf="botForm.tts_config.method">{{
                    botForm.tts_config.method.label
                  }}</span>
                  <i class="material-icons">expand_more</i>
                </div>
              </div>
              <div
                ngbDropdownMenu
                aria-labelledby="tts-provider-dropdown"
                class="modal-dropdown"
              >
                <div
                  ngbDropdownItem
                  class="dropdown-btn"
                  *ngFor="let type of apiMethods"
                  (click)="botForm.tts_config.method = type"
                >
                  {{ type.label }}
                </div>
              </div>
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'rest'
              "
            >
              <label>Voice Name<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="google_voice_name"
                [(ngModel)]="botForm.tts_config.voice.name"
                class="form-control"
                placeholder="Private The Voice Name From Google"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'rest'
              "
            >
              <label>SSML Gender<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="google_ssmlGender"
                [(ngModel)]="botForm.tts_config.voice.ssmlGender"
                class="form-control"
                placeholder="MALE or FEMALE"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'google' &&
                botForm.tts_config.credential_type &&
                botForm.tts_config.credential_type.value === 'rest'
              "
            >
              <label>Language Code<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="ttslanguageCode"
                [(ngModel)]="botForm.tts_config.languageCode"
                class="form-control"
                placeholder="Language Code for Google TTS"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'polly'
              "
            >
              <label>Access Key Id<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="accessKeyId"
                [(ngModel)]="botForm.tts_config.accessKeyId"
                class="form-control"
                placeholder="AWS Access Key Id"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'polly'
              "
            >
              <label
                >Secret Access Key<sup class="mandatory-symbol">*</sup></label
              >
              <input
                type="text"
                name="secretAccessKey"
                [(ngModel)]="botForm.tts_config.secretAccessKey"
                class="form-control"
                placeholder="AWS Secret Access Key"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'polly'
              "
            >
              <label>Polly Voice ID<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="VoiceId"
                [(ngModel)]="botForm.tts_config.VoiceId"
                class="form-control"
                placeholder="Voice ID of Polly"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'polly'
              "
            >
              <label>AWS Region<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="region"
                [(ngModel)]="botForm.tts_config.region"
                class="form-control"
                placeholder="AWS Region"
                required
              />
            </div>

            <div
              class="form-group col-md-6"
              *ngIf="
                botForm.tts_config.provider &&
                botForm.tts_config.provider.value === 'polly'
              "
            >
              <label>Language Code<sup class="mandatory-symbol">*</sup></label>
              <input
                type="text"
                name="ttslanguageCode"
                [(ngModel)]="botForm.tts_config.languageCode"
                class="form-control"
                placeholder="Language Code for Amazon Polly TTS"
                required
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="selectedBot">
    <div class="col-sm-12 align-buttons-right">
      <button class="btn lg btn-secondary" (click)="revertBotChanges()">
        Revert
      </button>
      <button class="btn lg btn-red" (click)="deleteBotExtension(selectedBot)">
        Delete
      </button>
      <button
        class="btn lg btn-primary"
        (click)="updateBotExtension(selectedBot)"
      >
        Save
      </button>
    </div>
  </div>
</div>
