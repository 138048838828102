import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RequestForAccessComponent } from "./request-for-access/request-for-access.component";
import { WarningMessageComponent } from "./warning-message/warning-message.component";
import { CreateEditUserComponent } from "./create-edit-user/create-edit-user.component";
import { EditProfileComponent } from "./edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { FormsModule } from "@angular/forms";
import { CreateEditOrganizationComponent } from "./create-edit-organization/create-edit-organization.component";
import { CreateEditDidComponent } from "./create-edit-did/create-edit-did.component";
import { CreateEditAgentComponent } from "./create-edit-agent/create-edit-agent.component";
import { CreateEditBotComponent } from "./create-edit-bot/create-edit-bot.component";
import { SwiperModule } from "ngx-swiper-wrapper-v-13";
import { UiSwitchModule } from "ngx-ui-switch";

@NgModule({
  declarations: [
    RequestForAccessComponent,
    WarningMessageComponent,
    CreateEditUserComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    CreateEditOrganizationComponent,
    CreateEditDidComponent,
    CreateEditAgentComponent,
    CreateEditBotComponent,
  ],
  imports: [
    SwiperModule,
    CommonModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    FormsModule,
    UiSwitchModule,
  ],
  exports: [RequestForAccessComponent],
})
export class ModalsModule {}
