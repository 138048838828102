import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent implements OnInit {
  @Input() data;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (!this.data.title) {
      this.data.title = 'Warning';
    }

    if (!this.data.message) {
      this.data.message = 'Do you want to proceed?';
    }

    if (!this.data.icon) {
      this.data.icon = 'assets/images/icons/warning.svg';
    }

    if (!this.data.okButton) {
      this.data.okButton = 'Yes';
    }

    if (!this.data.cancelButton) {
      this.data.cancelButton = 'No';
    }
  }

}
