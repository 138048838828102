<div class="modal-header">
  <div class="modal-title">Change Password</div>
  <div class="modal-title-image"><img src="assets/images/icons/password.svg"></div>
  <div class="modal-subtitle" *ngIf="!data.isFromEditProfile && !data.isAgentExtension">Change {{data.userDetails.first_name}}'s Password</div>
  <div class="modal-subtitle" *ngIf="data.isAgentExtension">Change {{data.agentExtension.name}}'s Password</div>
  <div class="modal-subtitle" *ngIf="data.isFromEditProfile">Change your Password</div>

  <i class="material-icons close" (click)="activeModal.dismiss('Cross click')">close</i>
</div>
<div class="modal-body">
  <form>
    <div class="form-group col-md-12" *ngIf="data.isFromEditProfile">
      <label>Old Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" name="password" [(ngModel)]="userForm.oldPassword" autocomplete="new-password"
             role="presentation" class="form-control" placeholder="Enter your old password" required>
    </div>
    <div class="form-group col-md-6">
      <label>New Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" name="password" [(ngModel)]="userForm.password" autocomplete="new-password"
             role="presentation" class="form-control" placeholder="New Password" required>
    </div>
    <div class="form-group col-md-6">
      <label>Confirm Password<sup class="mandatory-symbol">*</sup></label>
      <input type="password" autocomplete="new-password" name="confirmPassword" [(ngModel)]="userForm.confirmPassword" class="form-control"
             placeholder="Re-enter the new password" required>
    </div>
    <div class="form-group col-md-12 hint-text">
      Password must contain atleast one lower case, one upper case, one numeric and one special character
      (!,@,#,$,%,^,&,*) and with total length more than 8.
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="accept()">Change Password</button>
</div>

